import {gql} from '@apollo/client'

const M_CREATE_OPPONENT = gql`
  mutation createTeam($object: teams_insert_input = {}) {
    insert_teams_one(object: $object) {
      id
    }
  }
`

export {M_CREATE_OPPONENT}
