import React from 'react'
import {PageLayout} from '../components/PageLayout'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import {useFormSubmitter} from '../hooks/useFormSubmitter'
import {confirmSubmissionAndResetForm} from '../lib/confirm_submissions_and_reset_form'
// import { SnackBarInputType } from '../components/forms/Form.types'
import {SnackBarInputType} from '../components/forms/Form.types'
import SnackBar from '@mui/material/Snackbar'
import {M_CREATE_OPPONENT} from '../components/forms/mutations/CreateOpponent'

// Custom form components
import Alert from '@mui/material/Alert'
// import {useLocation} from 'react-router-dom'
import {FormAddOpponent} from '../components/forms/FormAddOpponent'
import {
  OpponentInputType,
  OpponentSchema,
  defaultValues,
} from '../components/forms/schemas/OpponentSchema'

export function AdminAddOpponent({handleSubmitFromProps = null}) {
  // const {control, handleSubmit, formErrors, onSubmit, apolloError} =
  // const {pathname} = useLocation()
  // console.log('pathname', pathname)

  const {
    control,
    handleSubmit: handleSubmitFromHook,
    formErrors,
    getValues,
    onSubmit,
    apolloError,
    reset,
    setValue,
  } = useFormSubmitter<OpponentInputType>(
    M_CREATE_OPPONENT,
    OpponentSchema,
    defaultValues
  )
  // Hacky workaround for tests.  Need to be able to override this function.
  const handleSubmit = handleSubmitFromProps ?? handleSubmitFromHook

  const [snackBarState, setSnackBarState] = React.useState<SnackBarInputType>({
    open: false,
    message: '',
    severity: undefined,
  })
  const onSubmitWrapper = confirmSubmissionAndResetForm<SnackBarInputType>(
    onSubmit,
    setSnackBarState,
    'opponent',
    reset
  )

  const onClose = () => {
    setSnackBarState({...snackBarState, open: false})
  }

  return (
    <PageLayout>
      <Container maxWidth="sm" sx={{backgroundColor: 'white'}}>
        <SnackBar
          open={snackBarState.open}
          onClose={onClose}
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert severity={snackBarState.severity} sx={{width: '100%'}}>
            <Box sx={{pt: 0.25, fontSize: '1rem'}}>{snackBarState.message}</Box>
          </Alert>
        </SnackBar>
        <Box>
          <h1>Add Opponent</h1>
        </Box>
        <FormAddOpponent
          handleSubmit={handleSubmit(onSubmitWrapper)}
          control={control}
          errors={formErrors}
          apolloError={apolloError}
          getValues={getValues}
          setValue={setValue}
        />
      </Container>
    </PageLayout>
  )
}
