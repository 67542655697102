import React from 'react'
import {gql} from '@apollo/client'
import {AutoCompleteInput} from './AutoCompleteInput'

const Q_LOCATION = gql`
  query Locations {
    games(distinct_on: location) {
      location
    }
  }
`

export function LocationInput(props) {
  return (
    <AutoCompleteInput
      query={Q_LOCATION}
      field="location"
      {...props}
      sx={{width: 182}}
    />
  )
}
