import React from 'react'
import {useGameContext} from './GameContext'
import {useFilters, getLastHistory} from './FilterContext'
import {useSort, defaultAggDirections, defaultDirections} from './SortContext'

export default function SearchBar(props: any) {
  const gameContext = useGameContext()
  const [filterState, filterDispatch]: any = useFilters()
  const {filters} = filterState
  const {
    games = [],
    teams,
    sites,
    nd: ndCoaches,
    opponents: oppCoaches,
  }: any = gameContext || {}
  const [sortState, sortDispatch]: any = useSort()

  function handleFilter(filterKey: string) {
    return (
      e: React.ChangeEvent<HTMLElement & HTMLSelectElement & HTMLInputElement>
    ) => {
      // props.onFilterChange(filterKey, e.target.value)
      // e.preventDefault() // This messes up the state of checkboxes
      const value: string | boolean =
        e.target.type === 'checkbox'
          ? e.target.checked
            ? true
            : ''
          : e.target.value

      filterDispatch({
        type: 'CHANGE_FILTER',
        filter: {key: filterKey, value: value},
      })

      // Set the sort direction for groupings.  Groupings need to have the name
      // of the order by column updated, because the column will change based
      // on the grouping.  (For non-groupings it's just the 'date'.)
      if (filterKey === 'groupings') {
        if (!value) {
          resetSortState()
          return
        }
        // console.log('group by', value)
        const orderCol = value
        const hasChange = sortState.order !== orderCol
        sortDispatch({
          order: orderCol,
          direction: hasChange
            ? defaultAggDirections[value as string]
            : undefined,
        })
      }
    }
  }

  function handleReset(e: React.ChangeEvent<any> | React.MouseEvent<any>) {
    filterDispatch({
      type: 'RESET',
    })
    resetSortState()
  }

  function handleBack(e: React.ChangeEvent<any> | React.MouseEvent<any>) {
    filterDispatch({
      type: 'GO_BACK',
    })

    // Duplicating this logic here, because when we "go back" in the history,
    // we have to update the sort state.  Might be better to merge the sort
    // and filter states
    const [, , lastFilters, canGoBack] = getLastHistory(filterState)

    if (canGoBack) {
      if (lastFilters.groupings) {
        sortDispatch({
          order: lastFilters.groupings,
          direction: defaultAggDirections[lastFilters.groupings],
        })
      } else {
        sortDispatch({order: 'date', direction: defaultDirections['date']})
      }
    }
  }

  function resetSortState() {
    sortDispatch({order: 'date', direction: defaultDirections['date']})
  }

  // const lastGame = games.at(-1)
  const lastGame = games[games.length - 1]
  // const currentYear = new Date(lastGame.date.split('-')).getFullYear() // date format doesn't work on IOS
  // const currentYear = new Date(`${lastGame.date} 00:00:00`).getFullYear()
  const currentYear = Number.parseInt(lastGame.date.split('-')[0])

  const MONTHS = [...Array(12).keys()]
  const DAYS = [...Array(32).keys()].slice(1)
  const YEARS = [...Array(currentYear + 1).keys()].slice(1887).reverse()

  const yearsOptions = YEARS.map((year) => {
    return (
      <option key={year} value={year}>
        {year}
      </option>
    )
  })

  const seasonsOptions = [...yearsOptions]
  seasonsOptions.unshift(<option key="" value="" />)

  yearsOptions.unshift(
    <option key="" value="">
      Year
    </option>
  )
  const monthsOptions = MONTHS.map((month: number) => (
    <option key={month} value={month}>
      {Number.isNaN(parseInt(month as unknown as string)) ? month : month + 1}
    </option>
  ))
  monthsOptions.unshift(
    <option key="" value="">
      Month
    </option>
  )
  const daysOptions = DAYS.map((day) => (
    <option key={day} value={day}>
      {day}
    </option>
  ))
  daysOptions.unshift(
    <option key="" value="">
      Day
    </option>
  )

  const siteOptions = sites.map((site: any) => {
    return (
      <option key={site.site} value={site.site.toLowerCase()}>
        {site.site}
      </option>
    )
  })
  siteOptions.unshift(<option key="" value="" />)

  const ndCoachesOptions = ndCoaches.map((coach: any) => {
    // let by_last_name = `${coach.last_name}, ${coach.first_name} ${coach.middle_name} ${coach.suffix}`
    let by_last_name = `${coach.last_name}, ${[
      coach.first_name,
      coach.middle_name,
      coach.suffix,
    ]
      .filter(Boolean)
      .join(' ')}`
    let by_first_name = [
      coach.first_name,
      coach.middle_name,
      coach.last_name,
      coach.suffix,
    ]
      .filter(Boolean)
      .join(' ')
    return (
      <option key={by_last_name} value={by_first_name.toLowerCase()}>
        {by_last_name}
      </option>
    )
  })

  ndCoachesOptions.unshift(<option key="" value="" />)

  const oppCoachesOptions = oppCoaches.map((coach: any) => {
    // let by_last_name = `${coach.last_name}, ${coach.first_name} ${coach.middle_name} ${coach.suffix}`
    let by_last_name = `${coach.last_name}, ${[
      coach.first_name,
      coach.middle_name,
      coach.suffix,
    ]
      .filter(Boolean)
      .join(' ')}`
    let by_first_name = [
      coach.first_name,
      coach.middle_name,
      coach.last_name,
      coach.suffix,
    ]
      .filter(Boolean)
      .join(' ')
    return (
      <option key={by_last_name} value={by_first_name.toLowerCase()}>
        {by_last_name}
      </option>
    )
  })

  oppCoachesOptions.unshift(<option key="" value="" />)

  const opponentsOptions = teams.map((team: any) => (
    <option key={team.name} value={team.name.toLowerCase()}>
      {team.name}
    </option>
  ))

  opponentsOptions.unshift(<option key="" value="" />)
  // const filters = props.filters

  const resultsOptions = [
    {name: '', abbrev: ''},
    {name: 'Win', abbrev: 'w'},
    {name: 'Loss', abbrev: 'l'},
    {name: 'Tie', abbrev: 't'},
  ].map((result) => (
    <option key={result.name} value={result.abbrev}>
      {result.name}
    </option>
  ))

  const vacatedOptions = [
    {key: 'Include', value: 'true'},
    {key: 'Exclude', value: 'false'},
  ].map(({key, value}) => (
    <option key={key} value={value}>
      {key}
    </option>
  ))
  vacatedOptions.unshift(<option key="" value="" />)

  const wonByNoLessThanOptions = [...Array(131).keys()]
    .map((i) => 80 - i)
    .map((i) => (
      <option key={i} value={i}>
        {i}
      </option>
    ))
  wonByNoLessThanOptions.unshift(
    <option key="" value="">
      At least
    </option>
  )

  const wonByNoMoreThanOptions = [...Array(131).keys()]
    .map((i) => 80 - i)
    .map((i) => (
      <option key={i} value={i}>
        {i}
      </option>
    ))
  wonByNoMoreThanOptions.unshift(
    <option key="" value="">
      At most
    </option>
  )

  const wonByExactlyOptions = [...Array(131).keys()]
    .map((i) => 80 - i)
    .map((i) => (
      <option key={i} value={i}>
        {i}
      </option>
    ))
  wonByExactlyOptions.unshift(
    <option key="" value="">
      Exactly
    </option>
  )

  const groupings: {[key: string]: string} = {
    'opp-coach__full_name': 'Opponent Coach',
    opponent__name: 'Opponent',
    'nd-coach__full_name': 'ND Coach',
    site: 'Site',
    season: 'Season',
  }

  const groupByOptions = Object.keys(groupings).map((k) => (
    <option key={k} value={k}>
      {groupings[k]}
    </option>
  ))
  groupByOptions.unshift(<option key="" value="" />)

  return (
    <div className="search-bar">
      <header className="search-bar-header">
        <h1>
          <span className="search-bar-header__title1">Notre Dame Football</span>{' '}
          <span className="search-bar-header__title2">Game Results</span>
        </h1>
      </header>
      <main className="main">
        <div className="filter-table">
          <div className="filter-table__row">
            <table className="table-filters">
              <thead></thead>
              <tbody>
                <tr>
                  <td>Site:</td>
                  <td>
                    <select
                      onChange={handleFilter('site')}
                      value={'site' in filters ? filters.site : ''}
                    >
                      {siteOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Vacated wins?:</td>
                  <td>
                    <select
                      onChange={handleFilter('vacate')}
                      value={'vacate' in filters ? filters.vacate : ''}
                    >
                      {vacatedOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Result:</td>
                  <td>
                    <select
                      onChange={handleFilter('result')}
                      value={'result' in filters ? filters.result : ''}
                    >
                      {resultsOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Bowl Game?:</td>
                  <td>
                    <select
                      onChange={handleFilter('isBowl')}
                      value={'isBowl' in filters ? filters.isBowl : ''}
                    >
                      {vacatedOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Notre Dame Coach:</td>
                  <td>
                    <select
                      onChange={handleFilter('ndCoach')}
                      value={'ndCoach' in filters ? filters.ndCoach : ''}
                    >
                      {ndCoachesOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Opponent:</td>
                  <td>
                    <select
                      onChange={handleFilter('opponent')}
                      value={'opponent' in filters ? filters.opponent : ''}
                    >
                      {opponentsOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Opponent Coach:</td>
                  <td>
                    <select
                      onChange={handleFilter('oppCoach')}
                      value={'oppCoach' in filters ? filters.oppCoach : ''}
                    >
                      {oppCoachesOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Season:</td>
                  <td>
                    <select
                      onChange={handleFilter('season')}
                      value={'season' in filters ? filters.season : ''}
                    >
                      {seasonsOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Date:</td>
                  <td>
                    <select
                      onChange={handleFilter('year')}
                      value={'year' in filters ? filters.year : ''}
                    >
                      {yearsOptions}
                    </select>
                    <select
                      onChange={handleFilter('month')}
                      value={'month' in filters ? filters.month : ''}
                    >
                      {monthsOptions}
                    </select>
                    <select
                      onChange={handleFilter('day')}
                      value={'day' in filters ? filters.day : ''}
                    >
                      {daysOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Won(+)/Lost(-) by:</td>
                  <td>
                    <select
                      onChange={handleFilter('wonByNoLessThan')}
                      value={
                        'wonByNoLessThan' in filters
                          ? filters.wonByNoLessThan
                          : ''
                      }
                    >
                      {wonByNoLessThanOptions}
                    </select>
                    <select
                      onChange={handleFilter('wonByNoMoreThan')}
                      value={
                        'wonByNoMoreThan' in filters
                          ? filters.wonByNoMoreThan
                          : ''
                      }
                    >
                      {wonByNoMoreThanOptions}
                    </select>
                    <span>
                      <select
                        onChange={handleFilter('wonByExactly')}
                        value={
                          'wonByExactly' in filters ? filters.wonByExactly : ''
                        }
                      >
                        {wonByExactlyOptions}
                      </select>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Group By:</td>
                  <td>
                    <select
                      onChange={handleFilter('groupings')}
                      value={'groupings' in filters ? filters.groupings : ''}
                    >
                      {groupByOptions}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Show rankings:</td>
                  <td>
                    <span>
                      <input
                        type="checkbox"
                        checked={!!filters.rankingAtMatch}
                        onChange={handleFilter('rankingAtMatch')}
                        className="search-checkbox search-checkbox--left"
                      />
                      at match
                      <input
                        type="checkbox"
                        checked={!!filters.rankingAtEndOfSeason}
                        onChange={handleFilter('rankingAtEndOfSeason')}
                        className="search-checkbox search-checkbox--right"
                        // onMouseOver={(e) => {
                        //   console.log(
                        //     e.target.checked,
                        //     e.target.value,
                        //     filters.rankingAtEndOfSeason
                        //   )
                        // }}
                      />
                      at end of season
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-1-of-2">
              <div className="filters__notre-dame">
                <div className="u-center-div-small"></div>
                <div className="u-center-div-medium"></div>
              </div>
            </div>
            <div className="col-1-of-2">
              <div className="filters__opponents">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div className="search-buttons">
        <button className="btn btn--color btn--go-back" onClick={handleBack}>
          Go Back
        </button>
        <button className="btn btn--color btn--reset" onClick={handleReset}>
          Reset
        </button>
      </div>
      <br />
    </div>
  )
}
