import React from 'react'
import {capitalize} from 'lodash'

type objectType = 'coach' | 'game' | 'opponent'

export function confirmSubmissionAndResetForm<T>(
  onSubmit: (...args: any[]) => any,
  setter: React.Dispatch<React.SetStateAction<T>>,
  // setter: (a: {open: boolean; message: string; severity: string}) => void,
  objectType: objectType,
  reset: () => void
) {
  return async (data: any) => {
    let result = await onSubmit(data)
    if ('errors' in result) {
      setter({
        open: true,
        message: `Could not create ${capitalize(objectType)}`,
        severity: 'error',
      } as T)
    } else {
      setter({
        open: true,
        message: `${capitalize(objectType)} created!`,
        severity: 'success',
      } as T)
      reset()
    }
  }
}
