import React from 'react'
import {DocumentNode, gql} from '@apollo/client'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {InputLabel} from '@mui/material'
import {Controller} from 'react-hook-form'
import {useLazyQuery} from '@apollo/client'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import {camelCase} from 'lodash'

// Look at a better way to handle these queries, seems redundant and overly complicated.
const Q_COACHES: {
  [key: string]: {
    variable: string
    query: DocumentNode
  }
} = {
  nd_coach: {
    variable: 'is_notre_dame',
    query: gql`
      query coaches($isNotreDame: Boolean!) {
        coaches(
          where: {is_notre_dame: {_eq: $isNotreDame}, active: {_eq: true}}
          order_by: {full_name: asc_nulls_first}
        ) {
          id
          full_name
        }
      }
    `,
  },
  opp_coach: {
    variable: 'is_opponent',
    query: gql`
      query coaches($isOpponent: Boolean!) {
        coaches(
          where: {is_opponent: {_eq: $isOpponent}, active: {_eq: true}}
          order_by: {full_name: asc_nulls_first}
        ) {
          id
          full_name
        }
      }
    `,
  },
}

export function CoachInput({
  label,
  control,
  field,
  errors,
}: {
  label: any
  control: any
  field: string
  errors: any
}) {
  // const isNotreDame = field.match(/opp_/) ? false : true
  const hasError = Boolean(errors) && Boolean(errors[field])
  const [getCoaches, {data}] = useLazyQuery(Q_COACHES[field].query, {
    variables: {[camelCase(Q_COACHES[field].variable)]: true},
  })

  const handleFocus = () => {
    getCoaches()
  }

  const coaches = data?.coaches || []

  return (
    <Controller
      name={field}
      control={control}
      render={({field: {onChange: handleChange, value = '', ...rest}}) => {
        return (
          <FormControl size="small" {...(hasError ? {error: true} : {})}>
            <InputLabel id={field}>{label}</InputLabel>
            <Select
              labelId={field}
              id={field + '-select'}
              value={value}
              label={label}
              onFocus={handleFocus}
              onChange={handleChange}
              sx={{width: 122}}
            >
              {coaches.map((coach: any) => (
                <MenuItem key={coach.id} value={coach.id}>
                  {coach.full_name}
                </MenuItem>
              ))}
            </Select>
            {hasError ? (
              <FormHelperText>{errors[field].message}</FormHelperText>
            ) : null}
          </FormControl>
        )
      }}
    />
  )
}
