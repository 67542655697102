import React from 'react'

const sortDirections: {[key: string]: string} = {
  asc: 'asc',
  desc: 'desc',
}

const defaultDirections: {[key: string]: string} = {
  date: 'desc',
  result: 'asc',
  site: 'asc',
  'nd-coach__full_name': 'asc',
  'opp-coach__full_name': 'asc',
  opponent__name: 'asc',
  nd_score: 'desc',
  opp_score: 'desc',
}

const defaultAggDirections: {[key: string]: string} = {
  'nd-coach__full_name': 'asc',
  'opp-coach__full_name': 'asc',
  opponent__name: 'asc',
  site: 'asc',
  season: 'desc',
  wins: 'desc',
  losses: 'desc',
  ties: 'desc',
  total: 'desc',
  percentage: 'desc',
}

const SortContext = React.createContext({})
SortContext.displayName = 'SortContext'

function useSort() {
  const context = React.useContext(SortContext)
  if (context === undefined) {
    throw new Error(`useSort must be used within a SortProvider`)
  }
  return context
}

function sortReducer(
  state: any,
  {order, direction}: {order: string; direction: string}
) {
  // console.log(`fired dispatch`, state, order, direction)
  const newDirection =
    direction ??
    (state.direction === sortDirections.asc
      ? sortDirections.desc
      : sortDirections.asc)

  return {
    order: order,
    direction: newDirection,
  }
}

function SortProvider(props: any) {
  const [state, dispatch] = React.useReducer(sortReducer, {
    // order: col.split('table-').slice(1).join('-'),
    order: 'date',
    direction: sortDirections.desc,
  })

  return <SortContext.Provider value={[state, dispatch]} {...props} />
}

// Helper function for sorting columns
function handleSort(
  e: any,
  sortState: any,
  sortDispatch: any,
  defaultDirections: any
) {
  // The sort id is the id of the element with "table-" stripped.
  // console.log('target id', e.target.id)
  // console.log(sortState.order)
  const orderCol = e.target.id.split('table-').slice(1).join('-')

  // If we're moving to a different column, don't change the sort direction,
  // instead let it toggle.  Otherwise use the default sort order for that
  // column
  const hasChanged = sortState.order !== orderCol
  const newDirection = hasChanged ? defaultDirections[orderCol] : undefined
  sortDispatch({
    order: orderCol,
    direction: newDirection,
  })
}

export {
  SortContext,
  useSort,
  SortProvider,
  defaultDirections,
  defaultAggDirections,
  sortDirections,
  handleSort,
}
