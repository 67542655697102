import * as Yup from 'yup'

const defaultValues = {}

const GameSchema = Yup.object().shape({
  date: Yup.date().required('Required'),
  is_bowl: Yup.boolean(),
  is_shamrock: Yup.boolean(),
  location: Yup.string().required('Required'),
  nd_coach: Yup.number().required('Required'),
  nd_final_rank: Yup.string().optional(),
  nd_rank: Yup.string().optional(),
  nd_score: Yup.number().required('Required'),
  opp_coach: Yup.number().required('Required'),
  opp_final_rank: Yup.string().optional(),
  opp_rank: Yup.string().optional(),
  opp_score: Yup.number().required('Required'),
  opp_team_id: Yup.number().required('Required'),
  result: Yup.string().required('Required'),
  season: Yup.number().required('Required'),
  // season: Yup.string().required('Required'),
  site: Yup.string().required('Required'),
  stadium: Yup.string().required('Required'),
})

type GameInputType = Yup.InferType<typeof GameSchema>

export {GameSchema, defaultValues, GameInputType}
