import React from 'react'
import {PageLayout} from '../components/PageLayout'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import {useFormSubmitter} from '../hooks/useFormSubmitter'
import {confirmSubmissionAndResetForm} from '../lib/confirm_submissions_and_reset_form'
// import { SnackBarInputType } from '../components/forms/Form.types'
import {SnackBarInputType} from '../components/forms/Form.types'
import SnackBar from '@mui/material/Snackbar'
import {M_CREATE_COACH} from '../components/forms/mutations/CreateCoach'
import {
  CoachInputType,
  CoachSchema,
  defaultValues,
  inputTransformer,
} from '../components/forms/schemas/CoachSchema'

// Custom form components
import {FormAddCoach} from '../components/forms/FormAddCoach'
import Alert from '@mui/material/Alert'

export function AdminAddCoach({handleSubmitFromProps = null}) {
  // const {control, handleSubmit, formErrors, onSubmit, apolloError} =
  const {
    control,
    handleSubmit: handleSubmitFromHook,
    formErrors,
    getValues,
    onSubmit,
    apolloError,
    reset,
    setValue,
  } = useFormSubmitter<CoachInputType>(
    M_CREATE_COACH,
    CoachSchema,
    defaultValues,
    inputTransformer
  )

  // Hacky workaround for tests.  Need to be able to override this function.
  const handleSubmit = handleSubmitFromProps ?? handleSubmitFromHook

  const [snackBarState, setSnackBarState] = React.useState<SnackBarInputType>({
    open: false,
    message: '',
    severity: undefined,
  })
  const onSubmitWrapper = confirmSubmissionAndResetForm<SnackBarInputType>(
    onSubmit,
    setSnackBarState,
    'coach',
    reset
  )

  const onClose = () => {
    setSnackBarState({...snackBarState, open: false})
  }

  return (
    <PageLayout>
      <Container maxWidth="sm" sx={{backgroundColor: 'white'}}>
        <SnackBar
          open={snackBarState.open}
          onClose={onClose}
          autoHideDuration={5000}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert severity={snackBarState.severity} sx={{width: '100%'}}>
            <Box sx={{pt: 0.25, fontSize: '1rem'}}>{snackBarState.message}</Box>
          </Alert>
        </SnackBar>
        <Box>
          <h1>Add Coach</h1>
        </Box>
        <FormAddCoach
          handleSubmit={handleSubmit(onSubmitWrapper)}
          control={control}
          errors={formErrors}
          apolloError={apolloError}
          getValues={getValues}
          setValue={setValue}
        />
      </Container>
    </PageLayout>
  )
}
