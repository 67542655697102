import React, {useState, useEffect} from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import {useAuth0} from '@auth0/auth0-react'
import {setContext} from '@apollo/client/link/context'
import {createClient} from 'graphql-ws'
import {GraphQLWsLink} from '@apollo/client/link/subscriptions'
import {getMainDefinition} from '@apollo/client/utilities'

export function ApolloWrapper({children}: {children: React.ReactNode}) {
  const [bearerToken, setBearerToken] = useState('')
  const {getAccessTokenSilently, isAuthenticated} = useAuth0()

  useEffect(() => {
    const getAccessToken = async () => {
      const token = isAuthenticated ? await getAccessTokenSilently() : ''
      setBearerToken(token)
    }
    getAccessToken()
  }, [getAccessTokenSilently, isAuthenticated, bearerToken])

  const httpLink = createHttpLink({
    uri: process.env.HASURA_GRAPHQL_URL,
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.HASURA_WSS_GRAPHQL_URL as string,
    })
  )

  const authLink = setContext((_, {headers, ...rest}) => {
    if (!bearerToken) {
      return {
        ...rest,
        headers,
      }
    }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${bearerToken}`,
      },
    }
  })

  const splitLink = split(
    ({query}) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
