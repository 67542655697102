import React, {LegacyRef} from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import AddCircle from '@mui/icons-material/AddCircle'
import IconButton from '@mui/material/IconButton'
import {StadiumInput} from '../../components/forms/StadiumInput'
import {SiteInput} from '../../components/forms/SiteInput'
import {SeasonInput} from '../../components/forms/SeasonInput'
import {ResultInput} from '../../components/forms/ResultInput'
import {OpponentInput} from '../../components/forms/OpponentInput'
import {RankInput} from '../../components/forms/RankInput'
import {NumericInput} from '../../components/forms/NumericInput'
import {CoachInput} from '../../components/forms/CoachInput'
import {LocationInput} from '../../components/forms/LocationInput'
import {CheckBoxInput} from '../../components/forms/CheckBoxInput'
import {DateInput} from '../../components/forms/DateInput'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import SnackBar from '@mui/material/Snackbar'

import {useMutation, useQuery, useLazyQuery} from '@apollo/client'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {ModalAddCoach} from './ModalAddCoach'
import {ModalAddOpponent} from './ModalAddOpponent'
import {M_CREATE_GAME} from './mutations/CreateGame'
import {GameSchema, GameInputType} from './schemas/GameSchema'
import Alert from '@mui/material/Alert'
import {confirmSubmissionAndResetForm} from '../../lib/confirm_submissions_and_reset_form'

const spaceBetweenColumns = 2
const spaceBetweenVerticalStacks = 1

const FormAddGame = React.forwardRef((props, ref) => {
  // const {id} = props
  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid},
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(GameSchema),
  })

  const [formState, setFormState] = React.useState({})
  const [opponentModalState, setOpponentModalState] = React.useState(false)
  const [coachModalState, setCoachModalState] = React.useState(false)
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    message: '',
    severity: undefined,
  })

  const [createGame, {data, loading, error}] = useMutation(M_CREATE_GAME, {
    variables: formState,
  })

  const handleOpenModal = (modal: any) => {
    modal === 'opponent' && setOpponentModalState(!opponentModalState)
    modal === 'coach' && setCoachModalState(!coachModalState)
  }

  const onSubmit = async (data: GameInputType) => {
    data.is_bowl = Boolean(data.is_bowl)
    data.is_shamrock = Boolean(data.is_shamrock)
    setFormState(data)

    let game: object = {}
    try {
      game = await createGame({
        variables: data,
      })
    } catch (error) {
      game = {
        errors: ['Could not create game'],
      }
    }
    return game
  }

  const onCloseSnackBar = () => {
    setSnackBarState({...snackBarState, open: false})
  }

  const onSubmitWrapper = confirmSubmissionAndResetForm(
    onSubmit,
    setSnackBarState,
    'game',
    reset
  )

  return (
    <div>
      <SnackBar
        open={snackBarState.open}
        onClose={onCloseSnackBar}
        autoHideDuration={5000}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert severity={snackBarState.severity} sx={{width: '100%'}}>
          <Box sx={{pt: 0.25, fontSize: '1rem'}}>{snackBarState.message}</Box>
        </Alert>
      </SnackBar>
      <form
        ref={ref as LegacyRef<HTMLFormElement>}
        onSubmit={handleSubmit(onSubmitWrapper)}
      >
        <Box>
          <Stack
            direction="row"
            spacing={spaceBetweenColumns}
            alignItems="flex-start"
          >
            <Stack
              direction="column"
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <DateInput field="date" control={control} errors={errors} />
              <Stack direction="row">
                <CheckBoxInput
                  field="is_shamrock"
                  label="Shamrock?"
                  control={control}
                />
                <CheckBoxInput
                  field="is_bowl"
                  label="Bowl?"
                  control={control}
                />
              </Stack>
            </Stack>
            <Stack spacing={spaceBetweenVerticalStacks}>
              <Stack direction="row" spacing={spaceBetweenColumns}>
                <NumericInput
                  label="ND Score"
                  field="nd_score"
                  control={control}
                  errors={errors}
                  sx={{width: 100}}
                />
                <NumericInput
                  label="Opponent Score"
                  field="opp_score"
                  control={control}
                  errors={errors}
                  sx={{width: 100}}
                />
              </Stack>
              <Stack direction="row" spacing={spaceBetweenColumns}>
                <SeasonInput
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
                <ResultInput
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              </Stack>
            </Stack>

            <Stack spacing={spaceBetweenVerticalStacks}>
              <Stack direction="row">
                <OpponentInput
                  field="opp_team_id"
                  control={control}
                  errors={errors}
                />
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Add an Opponent</Typography>
                    </React.Fragment>
                  }
                  placement="top"
                  arrow
                >
                  <IconButton
                    size="small"
                    aria-label="Add an Opponent"
                    aria-controls="game-form-opponent-input"
                    aria-haspopup="true"
                    onClick={() => handleOpenModal('opponent')}
                    color="primary"
                  >
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              </Stack>
              <SiteInput control={control} errors={errors} />
            </Stack>
            <Stack
              spacing={spaceBetweenVerticalStacks}
              sx={{'&.MuiStack-root': {marginLeft: '7px'}}}
            >
              <StadiumInput control={control} errors={errors} />
              <LocationInput control={control} errors={errors} />
            </Stack>
            <Stack spacing={spaceBetweenVerticalStacks}>
              <Stack direction="row">
                <CoachInput
                  label="ND Coach"
                  field="nd_coach"
                  control={control}
                  errors={errors}
                />
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Add a coach</Typography>
                    </React.Fragment>
                  }
                  placement="top"
                  arrow
                >
                  <IconButton
                    size="small"
                    aria-label="Add a Coach"
                    aria-controls="game-form-coach-input"
                    aria-haspopup="true"
                    onClick={() => handleOpenModal('coach')}
                    color="primary"
                  >
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              </Stack>
              <CoachInput
                label="Opponent Coach"
                field="opp_coach"
                control={control}
                errors={errors}
              />
            </Stack>
            <Stack
              spacing={spaceBetweenVerticalStacks}
              sx={{'&.MuiStack-root': {marginLeft: '7px'}}}
            >
              <RankInput
                label="ND Rank"
                field="nd_rank"
                control={control}
                errors={errors}
              />
              <RankInput
                label="ND Final Rank"
                field="nd_final_rank"
                control={control}
                errors={errors}
              />
            </Stack>
            <Stack spacing={spaceBetweenVerticalStacks}>
              <RankInput
                label="Opponent Rank"
                field="opp_rank"
                control={control}
                errors={errors}
              />
              <RankInput
                label="Opponent Final Rank"
                field="opp_final_rank"
                control={control}
                errors={errors}
              />
            </Stack>
          </Stack>
        </Box>
        {/* <Box>
          <button type="submit">Submit</button>
        </Box> */}
      </form>
      {/* The form validation breaks when these are inside the form. */}
      <ModalAddCoach state={coachModalState} handler={handleOpenModal} />
      <ModalAddOpponent state={opponentModalState} handler={handleOpenModal} />
    </div>
  )
})

export default FormAddGame
