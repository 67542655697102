import * as Yup from 'yup'

const defaultValues = {
  schools: {is_notre_dame: false, is_opponent: false},
}

const CoachSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  middle_name: Yup.string().optional(),
  last_name: Yup.string().required('Required'),
  suffix: Yup.string().optional(),
  schools: Yup.object()
    .shape({
      is_notre_dame: Yup.boolean().required(),
      is_opponent: Yup.boolean().required(),
    })
    .test('at-least-one-true', 'Select at least one school...', (value) => {
      const {is_notre_dame = false, is_opponent = false} = value
      if (is_notre_dame === true || is_opponent === true) {
        return true
      } else {
        return false
      }
    }),
})

// type CoachInputType = Yup.InferType<typeof CoachSchema>

type CoachInputType = {
  first_name: string
  middle_name: string
  last_name: string
  suffix: string
  // making this optional because of how we are deleting the key.
  // look for a better way to do this?
  schools?: {
    is_notre_dame: boolean
    is_opponent: boolean
  }
}

const inputTransformer = (data: CoachInputType) => {
  let dataCopy = {...data}
  const schools = {...dataCopy.schools}
  delete dataCopy.schools
  // support passing multiple coaches at some point
  return {
    objects: [{...dataCopy, ...schools}],
  }
}

export {CoachSchema, CoachInputType, inputTransformer, defaultValues}
