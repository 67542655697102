import React from 'react'

import {
  camelize,
  dedunderize,
  derefVal,
  compareGames,
} from '../lib/game_helpers'
import {
  useSort,
  sortDirections,
  defaultAggDirections,
  handleSort,
} from './SortContext'
import {useFilters} from './FilterContext'

import Icon from '../svg/icons.svg'

// Build a next object from a key.  This is being done so aggregated and non-aggregated
// sort id's can be kept consistent.
// For example:
// { opponent__name: 'Southern Cal' } -> {opponent: {name: 'Southern Cal'}}
function buildObject(obj) {
  const key = Object.keys(obj)?.[0]
  const value = Object.values(obj)?.[0]
  return key
    .split('__')
    .reverse()
    .reduce((p, c, i) => {
      return i === 0 ? {[c]: value} : {[camelize(c)]: p}
    }, {})
}

export default function GameResultsTableResultsAgg({
  colHeaders,
  matchingGames,
}) {
  // const aggOrderCol = getLastToken(Object.keys(colHeaders)[0])
  const aggOrderCol = Object.keys(colHeaders)[0]
  const [sortState, sortDispatch] = useSort()
  const [{filters}, filterDispatch] = useFilters()
  const resultRows = []
  let groupedGames = []
  const hasResults = matchingGames.length !== 0

  // Aggregate the matched games on the filter provided
  const groupings = dedunderize(filters.groupings)
  groupedGames = matchingGames.reduce((groups, game) => {
    const groupingVal = derefVal(game, groupings)
    groups[groupingVal] ??= {wins: 0, losses: 0, ties: 0, total: 0}

    if (game['result'] === 'W') {
      groups[groupingVal]['wins'] += 1
    } else if (game['result'] === 'L') {
      groups[groupingVal]['losses'] += 1
    } else {
      groups[groupingVal]['ties'] += 1
    }

    groups[groupingVal]['total'] += 1

    // This will be calculated every time, but it will be correct on
    // the last attempt and means we don't have to iterate again.  Seems
    // like this would be faster than iterating again.
    groups[groupingVal]['percentage'] = Number.parseFloat(
      Math.round(
        (groups[groupingVal]['wins'] / groups[groupingVal]['total']) * 10000.0
      ) / 10000
    ).toFixed(4)

    return groups
  }, {})

  groupedGames = Object.entries(groupedGames).map(([k, v]) => {
    return {...buildObject({[filters.groupings]: k}), ...v}
  })
  groupedGames.sort(compareGames(sortState.order, sortState.direction))

  groupedGames.forEach((row) => {
    const aggColValue = derefVal(row, dedunderize(aggOrderCol))
    resultRows.push(
      <tr key={aggColValue}>
        {Object.entries(row).map(([k, v], i) => {
          let isAggCol = i === 0 // The aggregate column should be the first one.
          v = typeof v === 'object' ? aggColValue : v

          return (
            <td
              key={k}
              className={`results-table__agg ${
                isAggCol ? 'results-table__agg-col' : null
              } results-table__agg-${k}`}
            >
              {isAggCol ? (
                <button
                  id={`agg-link-${k}`}
                  className="results-table__link-button"
                  onClick={(e) => {
                    e.preventDefault()
                    // console.log(aggOrderCol, k, e)
                    // onSetFilterFromAggregate(e, v.toLowerCase())
                    filterDispatch({
                      type: 'CHANGE_AGG',
                      filter: {
                        key: e.target.id.split('agg-link-')[1],
                        value: v.toLowerCase(),
                      },
                    })
                  }}
                >
                  {v}
                </button>
              ) : (
                v
              )}
            </td>
          )
        })}
      </tr>
    )
  })

  // Use alphabetical ordering to "toggle" current sort direction
  const sortDirection =
    sortState.direction > sortDirections.asc
      ? sortDirections.asc
      : sortDirections.desc

  return (
    <>
      <thead>
        <tr>
          {Object.entries(colHeaders).map(([k, v]) => {
            const active = sortState.order === k
            return (
              // NOTE: class names are used for sorting
              <th
                id={`table-${k}`}
                className={`results-table__header results-table__agg-${k}`}
                key={k}
                onClick={(e) => {
                  handleSort(e, sortState, sortDispatch, defaultAggDirections)
                }}
              >
                <svg
                  className={`results-table__header-icon ${
                    active && hasResults
                      ? `results-table__header-icon--active results-table__header-icon--${sortDirection}`
                      : null
                  }`}
                  fill="black"
                  width="14px"
                  height="14px"
                >
                  <use xlinkHref={`${Icon}#icon-sort-${sortDirection}`} />
                </svg>
                <span className={`results-table__header-span`}>{v}</span>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>{resultRows}</tbody>
    </>
  )
}
