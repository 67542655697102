import React from 'react'
import {Controller} from 'react-hook-form'
import {TextField} from '@mui/material'

export function NumericInput({
  control,
  field,
  label,
  errors,
  sx = {width: 300},
}) {
  const hasError = Boolean(errors) && Boolean(errors[field])
  return (
    <Controller
      name={field}
      control={control}
      render={({field: {onChange, value = '', ...rest}}) => {
        return (
          <div>
            <TextField
              id={field.replaceAll('_', '-') + '-input'}
              onChange={onChange}
              value={value}
              label={label}
              inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
              size="small"
              sx={sx}
              placeholder="Numberic value"
              onInvalid={(e) => {
                e.target.setCustomValidity('Please enter a number')
              }}
              {...(hasError ? {error: true} : {})}
              helperText={hasError ? errors[field].message : ''}
            />
          </div>
        )
      }}
    />
  )
}
