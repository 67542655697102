import * as Yup from 'yup'

const defaultValues = {}

const OpponentSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

type OpponentInputType = Yup.InferType<typeof OpponentSchema>

export {OpponentSchema, OpponentInputType, defaultValues}
