import React, {useEffect, useState} from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {PageLayout} from '../components/PageLayout'

export const Profile = () => {
  const {user} = useAuth0()
  // const [message, setMessage] = useState('')
  // const {user, isAuthenticated, getAccessTokenSilently} = useAuth0()

  // useEffect(() => {
  //   let isMounted = true

  //   const getMessage = async () => {
  //     const accessToken = await getAccessTokenSilently()
  //     console.log(accessToken)
  //     setMessage('blah')
  //     // const {data, error} = await getProtectedResource(accessToken)

  //     // if (!isMounted) {
  //     //   return
  //     // }

  //     // if (data) {
  //     //   setMessage(JSON.stringify(data, null, 2))
  //     // }

  //     // if (error) {
  //     //   setMessage(JSON.stringify(error, null, 2))
  //     // }
  //   }

  //   getMessage()

  //   return () => {
  //     isMounted = false
  //   }
  // }, [getAccessTokenSilently])

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Profile Page
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              <strong>User token:</strong>
            </span>
          </p>
          <pre>Token: {JSON.stringify(user, null, 2)}</pre>
        </div>
      </div>
    </PageLayout>
  )
}
