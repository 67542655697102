import { gql } from "@apollo/client"

const M_CREATE_COACH = gql`
  mutation createCoach($objects: [coaches_insert_input!]!) {
    insert_coaches(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export {M_CREATE_COACH}
