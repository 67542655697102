const gameResultsHeaders = {
  date: 'Date',
  result: 'Result',
  site: 'Site',
  'nd-coach__full_name': 'ND Coach',
  'opp-coach__full_name': 'Opponent Coach',
  nd_score: 'ND Score',
  opp_score: 'Opponent Score',
  opponent__name: 'Opponent',
}

const gameResultsHeadersAgg = {
  wins: 'Wins',
  losses: 'Losses',
  ties: 'Ties',
  total: 'Total',
  percentage: 'Win %',
}

function includeGame(filters, game) {
  // Check if year matches
  let gameDate = new Date(game.date.replace(/-/g, '/'))

  if ('year' in filters) {
    let year = gameDate.getFullYear()
    if (Number(year) !== Number(filters.year)) return false
  }

  if ('month' in filters) {
    // console.log('Check Month')
    let month = gameDate.getMonth()
    if (Number(month) !== Number(filters.month)) return false
  }

  if ('day' in filters) {
    // console.log('Check Day')
    let day = gameDate.getDate()
    if (Number(day) !== Number(filters.day)) return false
  }

  if ('ndCoach' in filters) {
    let coach = game.ndCoach.full_name.toLowerCase()
    if (coach !== filters.ndCoach) return false
  }

  if ('oppCoach' in filters) {
    let coach = game.oppCoach.full_name.toLowerCase()
    if (coach !== filters.oppCoach) return false
  }

  if ('opponent' in filters) {
    let opponent = game.opponent.name.toLowerCase()
    if (opponent !== filters.opponent) return false
  }

  if ('result' in filters) {
    let result = game.result.toLowerCase()
    if (result !== filters.result) return false
  }

  if ('site' in filters) {
    let site = game.site.toLowerCase()
    if (site !== filters.site) return false
  }

  if ('vacate' in filters && filters.vacate.toLowerCase() == 'false') {
    let year = gameDate.getFullYear()
    let result = game.result.toLowerCase()

    if ((year == '2012' || year == '2013') && result == 'w') {
      return false
    }
  }

  if ('wonByNoLessThan' in filters) {
    let wonByNoLessThan = filters.wonByNoLessThan
    let ndScore = game.nd_score
    let oppScore = game.opp_score
    let scoreDiff = ndScore - oppScore
    let gameResult = game.result

    if (wonByNoLessThan > 0) {
      if (scoreDiff <= 0 || scoreDiff < wonByNoLessThan) {
        return false
      }
    } else if (wonByNoLessThan < 0) {
      if (scoreDiff > 0 || scoreDiff > wonByNoLessThan) {
        return false
      }
    } else {
      // Anything else should be a tie.
      if (gameResult != 'T') {
        return false
      }
    }
  }

  if ('wonByNoMoreThan' in filters) {
    let wonByNoMoreThan = filters.wonByNoMoreThan
    let ndScore = game.nd_score
    let oppScore = game.opp_score
    let scoreDiff = ndScore - oppScore
    let gameResult = game.result

    if (wonByNoMoreThan > 0) {
      if (scoreDiff <= 0 || scoreDiff > wonByNoMoreThan) {
        return false
      }
    } else if (wonByNoMoreThan < 0) {
      if (scoreDiff > -1 || scoreDiff < wonByNoMoreThan) {
        return false
      }
    } else {
      // Anything else should be a tie.
      if (gameResult != 'T') {
        return false
      }
    }
  }

  if ('wonByExactly' in filters) {
    let wonByExactly = filters.wonByExactly
    let ndScore = game.nd_score
    let oppScore = game.opp_score
    let scoreDiff = ndScore - oppScore
    let gameResult = game.result

    if (scoreDiff != wonByExactly) {
      return false
    }
  }

  if ('season' in filters) {
    let season = game.season

    if (season !== parseInt(filters.season)) {
      return false
    }
  }

  if ('isBowl' in filters) {
    let isBowl = game.is_bowl.toString()
    let bowlFilter = filters.isBowl

    if (isBowl !== bowlFilter) {
      return false
    }
  }

  return true
}

function calculateRecordFor(games) {
  let wins = 0,
    losses = 0,
    ties = 0,
    total = 0

  games.forEach((game) => {
    if (game.result === 'W') wins += 1
    else if (game.result === 'L') losses += 1
    else if (game.result === 'T') ties += 1
  })
  total = wins + losses + ties
  return {wins, losses, ties, total}
}

function calculatePointsFor(games) {
  let scored = 0,
    allowed = 0

  games.forEach((game) => {
    scored += parseInt(game.nd_score)
    allowed += parseInt(game.opp_score)
  })

  return {scored, allowed}
}

// Replace "__" with "." and "-" with camelcase.  This is for Hasura.
// For example:
// camelize=true : nd-score__full_name is returned as ndScore.full_name\
// camelize=false : nd-score__full_name is returned as ndScore.full_name\
function dedunderize(key, camelize = true) {
  var dedunderizedKey = '',
    sawDash = false,
    key = key.replaceAll('__', '.'),
    numTokens = key.split('.').length - 1,
    currTokens = 0

  if (numTokens === 0) {
    dedunderizedKey = key
  }

  for (var z = 0; z < key.length && numTokens > 0; z++) {
    var val = key[z].toLowerCase()

    if (val === '.') {
      currTokens++
      if (currTokens >= numTokens) {
        dedunderizedKey += key.slice(key.lastIndexOf('.'))
        break
      }
    }

    if (sawDash) {
      val = val.toUpperCase()
      sawDash = false
    } else {
      if (val === '-' && camelize) {
        sawDash = true
        continue
      }
    }

    dedunderizedKey += val
  }
  return dedunderizedKey
}

// Derefence a value from an object using a string.
// ie: obj = {one: {two: {three: 3}}}
//      derefVal(obj, 'one.two.three') => 3
function derefVal(obj, dedunderizedKey) {
  var keys = dedunderizedKey.split('.')
  keys.forEach((k, i) => {
    obj = obj[k]
  })
  return obj
}

// We may need to sort by subkeys (ie: properties) of an object.  A "-" (dash)
// represents a dereference of an object, while a "__" (dunder) represents
// a value to be sorted by.  For example:
// nd-score__full_name is looked up as ndScore.full_name
// the "ndScore" object is retrieved, and then the "full_name" property on that
// value is used for sorting.
// This is done to handle the Hasura naming style.  Objects use camelCase while
// properties use snake_case.
function compareGames(key, ordering = 'asc') {
  var dedunderizedKey = dedunderize(key)
  return function innerSort(a, b) {
    var sortOrder = 0
    // console.log(a)
    // console.log(b)
    // console.log(dedunderizedKey)
    // console.log('-')
    // window.a = a
    // window.b = b
    var aVal = derefVal(a, dedunderizedKey),
      bVal = derefVal(b, dedunderizedKey)

    if (aVal > bVal) {
      sortOrder = 1
    } else if (aVal < bVal) {
      sortOrder = -1
    }
    // console.log(sortOrder)

    return sortOrder * (ordering === 'asc' ? 1 : -1)
  }
}

// Return the last token delimited by '__'
// ie: opp-coaches__full_name => full_name
export function getLastToken(field) {
  const tokens = field.split('__')
  return tokens[tokens.length - 1]
}

export function getFieldName(field) {
  const lastToken = getLastToken(field).replaceAll(/_|-/g, ' ')
  return lastToken
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ')
}

function getAggCols(field) {
  return {
    [field]: getFieldName(field),
    ...gameResultsHeadersAgg,
  }
}

// Camelcase an input string
// ie: 'nd-coach' -> 'ndCoach'
function camelize(s) {
  let newString = '',
    sawDash = false
  for (let i = 0; i < s.length; i++) {
    let c = s[i]
    if (c === '-') {
      sawDash = true
      continue
    } else {
      if (sawDash) {
        sawDash = false
        c = c.toUpperCase()
      }
    }
    newString += c
  }
  return newString
}

export {
  calculatePointsFor,
  calculateRecordFor,
  camelize,
  compareGames,
  dedunderize,
  derefVal,
  gameResultsHeaders,
  gameResultsHeadersAgg,
  getAggCols,
  includeGame,
}
