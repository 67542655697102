import React from 'react'
import Stack from '@mui/material/Stack'
import TextInput from './TextInput'
import {CheckBoxGroupInput} from './CheckBoxGroupInput'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

export function FormAddCoach({
  handleSubmit,
  control,
  errors,
  getValues,
  apolloError,
  setValue,
}: {
  handleSubmit: any
  control: any
  errors: any
  getValues: any
  apolloError: any
  setValue: any
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1}>
        <TextInput
          label="First Name"
          field="first_name"
          control={control}
          errors={errors}
        />

        <TextInput
          label="Middle Name"
          field="middle_name"
          control={control}
          errors={errors}
        />

        <TextInput
          label="Last Name"
          field="last_name"
          control={control}
          errors={errors}
        />

        <TextInput
          label="Suffix"
          field="suffix"
          control={control}
          errors={errors}
        />
        <CheckBoxGroupInput
          control={control}
          name="schools"
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          options={[
            {id: 'is_notre_dame', label: 'Coached at Notre Dame?'},
            {id: 'is_opponent', label: 'Coached at opponent school?'},
          ]}
        />

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        <FormControl error={Boolean(apolloError)}>
          <FormHelperText>
            {apolloError
              ? `Error submitting to server: ${apolloError.message}`
              : null}
          </FormHelperText>
        </FormControl>
      </Stack>
    </form>
  )
}
