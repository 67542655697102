import gql from 'graphql-tag'

export const ALL_DATA = gql`
  query AllData {
    nd: coaches(
      where: {is_notre_dame: {_eq: "true"}}
      order_by: {last_name: asc}
    ) {
      id
      first_name
      middle_name
      last_name
      suffix
      full_name
    }

    opponents: coaches(
      where: {is_opponent: {_eq: "true"}}
      order_by: {last_name: asc}
    ) {
      id
      first_name
      middle_name
      last_name
      suffix
      full_name
    }

    teams: teams(order_by: {name: asc}) {
      id
      name
      nickname
      wiki_team_url {
        url
      }
    }

    games: games(order_by: {date: asc}) {
      id
      date
      nd_rank
      nd_final_rank
      result
      site
      season
      is_bowl
      ndCoach {
        id
        full_name
        wiki_url
      }
      nd_score
      opp_score
      opponent {
        name
      }
      opp_rank
      opp_final_rank
      oppCoach {
        id
        full_name
        wiki_url
      }
    }

    sites: games(distinct_on: site) {
      site
    }
  }
`
