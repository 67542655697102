import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl'
import {useController} from 'react-hook-form'
import FormHelperText from '@mui/material/FormHelperText'

// Custom form components
export function CheckBoxGroupInput({
  control,
  name,
  options,
  errors,
  getValues,
  setValue,
}: {
    control: any
    name: string
    options: any
    errors: any
    getValues: any
    setValue: any
}) {
  const {field} = useController({name, control})
  const hasError = errors[name] ? true : false

  return (
    <FormControl error={true}>
      {hasError ? (
        <FormHelperText sx={{fontSize: 10, ml: 0, pl: 0}}>
          {errors[name].message}
        </FormHelperText>
      ) : null}
      <FormGroup row>
        {options.map((option: any, index: any) => {
          return (
            <FormControlLabel
              name="schools"
              control={
                <Checkbox
                  {...field}
                  onChange={(e) => {
                    const valueCopy = getValues('schools')
                    valueCopy[option.id] = e.target.checked ? true : false
                    setValue('schools', valueCopy)
                  }}
                  value={option.id}
                  checked={getValues('schools')[option.id]}
                />
              }
              label={option.label}
              key={index}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}
