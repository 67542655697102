import React, {StrictMode} from 'react'
import {hot} from 'react-hot-loader'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {NotFound} from './pages/NotFound'
import {Admin} from './pages/Admin'
import {Admin2} from './pages/Admin2'
import {Dashboard} from './pages/Dashboard'
import {Profile} from './pages/Profile'
import {Callback} from './pages/Callback'
import {AuthenticationGuard} from './components/AuthenticationGuard'
import {Auth0ProviderWithNavigate} from './components/Auth0ProviderWithNavigate'
import {ApolloWrapper} from './components/ApolloWrapper'
import {AdminAddCoach} from './pages/AdminAddCoach'
import {AdminAddGame} from './pages/AdminAddGame'
import {AdminAddOpponent} from './pages/AdminAddOpponent'
import App from './App'

function WrappedApp() {
  // Skip authentication in development mode
  // Putting this here instead of teh AuthenticatedGuard, because
  // you shouldn't conditionally call a hook.
  let hasAuthentication =
    process.env.SKIP_AUTHENTICATION === 'true' &&
    process.env.NODE_ENV === 'development'
      ? false
      : true
  process.env.NODE_ENV === 'development' &&
    console.log(
      'Authentication',
      process.env.SKIP_AUTHENTICATION,
      process.env.NODE_ENV
    )

  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <ApolloWrapper>
          <Routes>
            <Route
              path="/"
              element={
                <StrictMode>
                  <App />
                </StrictMode>
              }
            />
            <Route path="/admin3" element={<Admin />} />
            <Route path="/admin2" element={<Admin2 />} />
            <Route path="/admin/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/admin/coach"
              element={
                hasAuthentication ? (
                  <AuthenticationGuard component={AdminAddCoach} />
                ) : (
                  <AdminAddCoach />
                )
              }
            />
            <Route
              path="/admin"
              element={
                hasAuthentication ? (
                  <AuthenticationGuard component={AdminAddGame} />
                ) : (
                  <AdminAddGame />
                )
              }
            />
            <Route
              path="/admin/opponent"
              element={
                hasAuthentication ? (
                  <AuthenticationGuard component={AdminAddOpponent} />
                ) : (
                  <AdminAddOpponent />
                )
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ApolloWrapper>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  )
}

export default hot(module)(WrappedApp)
