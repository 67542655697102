import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {Button} from './lib'

export function LoginButton() {
  const {loginWithRedirect, ...rest} = useAuth0()
  return (
    <Button
      onClick={() => loginWithRedirect({appState: {returnTo: '/profile'}})}
    >
      Login
    </Button>
  )
}
