import {gql} from '@apollo/client'

const M_CREATE_GAME = gql`
  mutation createGame(
    $date: date!
    $is_bowl: Boolean!
    $is_shamrock: Boolean!
    $location: String!
    $nd_coach: Int!
    $nd_final_rank: String
    $nd_rank: String
    $nd_score: Int!
    $opp_coach: Int!
    $opp_final_rank: String
    $opp_rank: String
    $opp_score: Int!
    $opp_team_id: Int!
    $result: String!
    $season: Int!
    $site: String!
    $stadium: String!
  ) {
    insert_games_one(
      object: {
        date: $date
        is_bowl: $is_bowl
        is_shamrock: $is_shamrock
        location: $location
        nd_coach: $nd_coach
        nd_final_rank: $nd_final_rank
        nd_rank: $nd_rank
        nd_score: $nd_score
        opp_coach: $opp_coach
        opp_final_rank: $opp_final_rank
        opp_rank: $opp_rank
        opp_score: $opp_score
        opp_team_id: $opp_team_id
        result: $result
        season: $season
        site: $site
        stadium: $stadium
      }
    ) {
      id
    }
  }
`

export {M_CREATE_GAME}
