import React from 'react'
import {useLazyQuery} from '@apollo/client'
import {Controller, useForm} from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import {capitalize} from 'lodash'
import {getOperationName} from '@apollo/client/utilities'
import Stack from '@mui/material/Stack'

export function AutoCompleteInput({
  control,
  field,
  query,
  label,
  errors,
  initialValue,
  sx = {width: 300},
}) {
  label = label || capitalize(field)
  const [getValues, {data}] = useLazyQuery(query)
  // Will not work for multiple queries
  const selection = query.definitions[0].selectionSet.selections[0].name.value
  // const options =
  //   games?.games?.map((game) => game[label]).filter((value) => value != null) ||
  //   []
  const options =
    data?.[selection]?.map((x) => x[field]).filter((value) => value != null) ||
    []
  const hasError = Boolean(errors) && Boolean(errors[field])

  const handleFocus = (e, cb) => {
    cb() // issue query
  }

  const handleChange = (e, newValue, onChange) => {
    onChange(!!e.target.value ? e.target.value : newValue)
  }

  return (
    <Controller
      name={field}
      control={control}
      render={({field: {onChange, value, ...rest}}) => {
        return (
          <Stack direction="column" spacing={0}>
            <Autocomplete
              sx={sx}
              freeSolo
              size="small"
              options={options}
              getOptionLabel={(option) => option.toString()}
              onFocus={(e) => handleFocus(e, getValues)}
              onChange={(e, newValue) => handleChange(e, newValue, onChange)}
              onInputChange={(e, newValue) =>
                handleChange(e, newValue, onChange)
              }
              renderInput={(params) => {
                // An initial input value can optionally be passed
                const newParams = {
                  ...params,
                  inputProps: {
                    ...params.inputProps,
                    value: value || initialValue || '',
                  },
                }
                return (
                  <TextField
                    {...(hasError ? {error: true} : {})}
                    value={value}
                    {...newParams}
                    label={capitalize(label)}
                    helperText={hasError ? errors[field].message : ''}
                  />
                )
              }}
            />
          </Stack>
        )
      }}
    />
  )
}
