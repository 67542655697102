// import React from 'react'
// import {gql, useLazyQuery} from '@apollo/client'
// import {Controller, useForm} from 'react-hook-form'
// import TextField from '@mui/material/TextField'
// import Autocomplete from '@mui/material/Autocomplete'
// import {AutoCompleteInput} from './AutoCompleteInput'
import React from 'react'
import {gql} from '@apollo/client'
import {AutoCompleteInput} from './AutoCompleteInput'

const Q_STADIUMS = gql`
  query Stadiums {
    games(distinct_on: stadium) {
      stadium
    }
  }
`

// export function StadiumInput({control}) {
//   console.log(`stadium component`, StadiumInput.displayName)
//   const [getStadiums, {data: games}] = useLazyQuery(Q_STADIUMS)
//   // console.log(`games`, games)
//   const stadiums =
//     games?.games
//       ?.map((game) => game.stadium)
//       .filter((stadium) => stadium != null) || []
//   // console.log('stadiums', stadiums)

//   const handleFocus = (e, cb) => {
//     // console.log('focus')
//     // console.log(e)
//     cb() // issue query
//   }

//   const handleChange = (e, newValue, onChange) => {
//     // console.log('onChange', !!e.target.value ? e.target.value : newValue)
//     onChange(!!e.target.value ? e.target.value : newValue)
//   }

//   return (
//     <Controller
//       name="stadium"
//       control={control}
//       render={({field: {onChange, value, ...rest}}) => {
//         return (
//           <Autocomplete
//             sx={{width: 300}}
//             freeSolo
//             size="small"
//             options={stadiums}
//             onFocus={(e) => handleFocus(e, getStadiums)}
//             onChange={(e, newValue) => handleChange(e, newValue, onChange)}
//             onInputChange={(e, newValue) => handleChange(e, newValue, onChange)}
//             renderInput={(params) => {
//               return <TextField value={value} {...params} label="Stadium" />
//             }}
//           />
//         )
//       }}
//     />
//   )
// }

export function StadiumInput(props) {
  return (
    <AutoCompleteInput
      query={Q_STADIUMS}
      field="stadium"
      {...props}
      sx={{width: 182}}
    />
  )
}
