import React from 'react'
import {NavBar} from './NavBar'

export const PageLayout = ({children}) => {
  return (
    <div>
      <NavBar />
      <br />
      <div>{children}</div>
    </div>
  )
}
