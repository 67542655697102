/** @jsx jsx */
import {jsx} from '@emotion/react'

import React from 'react'
import {Modal, ModalOpenButton, ModalContents} from '../components/modal'
// import {Button} from '../components/lib'
// import 'bootstrap/dist/css/bootstrap-reboot.css'
import '@reach/dialog/styles.css'
import {useAuth0} from '@auth0/auth0-react'
import {LoginButton} from '../components/LoginButton'
import {LogoutButton} from '../components/LogoutButton'

// function LoginButton() {
//   const {loginWithRedirect, ...rest} = useAuth0()
//   console.log(rest)
//   return (
//     <Button
//       onClick={() => loginWithRedirect({appState: {returnTo: '/profile'}})}
//     >
//       Login
//     </Button>
//   )
// }

// function LogoutButton() {
//   const {logout} = useAuth0()
//   return (
//     <Button
//       onClick={() =>
//         logout({
//           logoutParams: {
//             returnTo: `${window.location.origin}/admin2`,
//           },
//         })
//       }
//     >
//       Logout
//     </Button>
//   )
// }

function Profile() {
  const {user, isAuthenticated, isLoading} = useAuth0()
  if (isLoading) {
    return <div>Loading ...</div>
  }

  if (isAuthenticated) {
    console.log(user)
  }

  return (
    isAuthenticated && (
      <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
      </div>
    )
  )
}

function Admin2() {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '50vh',
      }}
    >
      <h1>Admin</h1>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
          gridGap: '0.75rem',
        }}
      >
        <Profile />
        <Modal>
          <ModalOpenButton>
            <LoginButton />
          </ModalOpenButton>
        </Modal>
        <Modal>
          <ModalOpenButton>
            <LogoutButton />
          </ModalOpenButton>
        </Modal>
      </div>
    </div>
  )
}

export {Admin2}
