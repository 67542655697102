import React from 'react'
import {gql} from '@apollo/client'
import {AutoCompleteInput} from './AutoCompleteInput'

const Q_SITE = gql`
  query Sites {
    games(distinct_on: site) {
      site
    }
  }
`

export function SiteInput(props) {
  return (
    <AutoCompleteInput
      query={Q_SITE}
      field="site"
      {...props}
      sx={{width: 142}}
    />
  )
}
