"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Admin = Admin;
var _react = require("@emotion/react");
var _react2 = _interopRequireDefault(require("react"));
var _modal = require("../components/modal");
var _lib = require("../components/lib");
require("@reach/dialog/styles.css");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/** @jsx jsx */

// import 'bootstrap/dist/css/bootstrap-reboot.css'

function LoginForm(props) {
  function handleSubmit(e) {
    e.preventDefault();
    props.onSubmit();
  }
  return (0, _react.jsx)("form", {
    css: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      '> div': {
        margin: '10px auto',
        width: '100%',
        maxWidth: '300px'
      }
    },
    onSubmit: handleSubmit
  }, (0, _react.jsx)(_lib.FormGroup, null, (0, _react.jsx)("label", {
    htmlFor: "username"
  }, "Username"), (0, _react.jsx)(_lib.Input, {
    type: "text",
    name: "username"
  })), (0, _react.jsx)(_lib.FormGroup, null, (0, _react.jsx)("label", {
    htmlFor: "password"
  }, "Password"), (0, _react.jsx)(_lib.Input, {
    type: "password",
    name: "password"
  })), (0, _react.jsx)("div", null, props.submitButton));
}
function login() {
  console.log('login');
}
function register() {
  console.log('register');
}
function Admin() {
  return (0, _react.jsx)("div", {
    css: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '50vh'
    }
  }, (0, _react.jsx)("h1", null, "Admin"), (0, _react.jsx)("div", {
    css: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
      gridGap: '0.75rem'
    }
  }, (0, _react.jsx)(_modal.Modal, null, (0, _react.jsx)(_modal.ModalOpenButton, null, (0, _react.jsx)(_lib.Button, {
    onClick: () => console.log('Login')
  }, "Login")), (0, _react.jsx)(_modal.ModalContents, {
    "aria-label": "Login Form",
    title: "Login"
  }, (0, _react.jsx)(LoginForm, {
    onSubmit: login,
    submitButton: (0, _react.jsx)(_lib.Button, null, "Login")
  }))), (0, _react.jsx)(_modal.Modal, null, (0, _react.jsx)(_modal.ModalOpenButton, null, (0, _react.jsx)(_lib.Button, {
    variant: "secondary",
    onClick: () => console.log('Register')
  }, "Register")), (0, _react.jsx)(_modal.ModalContents, {
    "aria-label": "Register Form",
    title: "Register"
  }, (0, _react.jsx)(LoginForm, {
    onSubmit: register,
    submitButton: (0, _react.jsx)(_lib.Button, {
      variant: "secondary"
    }, "Register")
  })))));
}