import React from 'react'
import Stack from '@mui/material/Stack'
import TextInput from './TextInput'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

export function FormAddOpponent({
  handleSubmit,
  control,
  errors,
  getValues,
  apolloError,
  setValue,
}: {
  handleSubmit: any
  control: any
  errors: any
  getValues: any
  apolloError: any
  setValue: any
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={1}>
        <TextInput
          label="Opponent Name"
          field="name"
          control={control}
          errors={errors}
        />

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        <FormControl error={Boolean(apolloError)}>
          <FormHelperText>
            {apolloError
              ? `Error submitting to server: ${apolloError.message}`
              : null}
          </FormHelperText>
        </FormControl>
      </Stack>
    </form>
  )
}
