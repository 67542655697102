/** @jsx jsx */
import {jsx} from '@emotion/react'

import React from 'react'
import {Modal, ModalOpenButton, ModalContents} from '../components/modal'
import {Button, Input, FormGroup} from '../components/lib'
// import 'bootstrap/dist/css/bootstrap-reboot.css'
import '@reach/dialog/styles.css'

function LoginForm(props) {
  function handleSubmit(e) {
    e.preventDefault()
    props.onSubmit()
  }

  return (
    <form
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        '> div': {
          margin: '10px auto',
          width: '100%',
          maxWidth: '300px',
        },
      }}
      onSubmit={handleSubmit}
    >
      <FormGroup>
        <label htmlFor="username">Username</label>
        <Input type="text" name="username" />
      </FormGroup>
      <FormGroup>
        <label htmlFor="password">Password</label>
        <Input type="password" name="password" />
      </FormGroup>
      <div>{props.submitButton}</div>
    </form>
  )
}

function login() {
  console.log('login')
}

function register() {
  console.log('register')
}

function Admin() {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '50vh',
      }}
    >
      <h1>Admin</h1>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
          gridGap: '0.75rem',
        }}
      >
        <Modal>
          <ModalOpenButton>
            <Button onClick={() => console.log('Login')}>Login</Button>
          </ModalOpenButton>
          <ModalContents aria-label="Login Form" title="Login">
            <LoginForm onSubmit={login} submitButton={<Button>Login</Button>} />
          </ModalContents>
        </Modal>
        <Modal>
          <ModalOpenButton>
            <Button variant="secondary" onClick={() => console.log('Register')}>
              Register
            </Button>
          </ModalOpenButton>
          <ModalContents aria-label="Register Form" title="Register">
            <LoginForm
              onSubmit={register}
              submitButton={<Button variant="secondary">Register</Button>}
            />
          </ModalContents>
        </Modal>
      </div>
    </div>
  )
}

export {Admin}
