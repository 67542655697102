import React from 'react'

const HISTORY_SIZE = 50
const DEFAULT_FILTERS = {rankingAtMatch: true}
const DEFAULT_HISTORY = {
  items: [DEFAULT_FILTERS],
  currentIndex: 0,
  stopBackIndex: null,
}
const skipFilters = ['rankingAtMatch', 'rankingAtEndOfSeason']

// If if only one input in a group should be set at at time, specify them in an array:
const mutuallyExclusiveFilters = [
  ['rankingAtMatch', 'rankingAtEndOfSeason'],
  ['add', 'groups', 'like', 'this'],
]

// does it make sense to list these out, or can we generate them?
// note: we can get an error if this is used incorrectly, for example if
// histories is set to [{filters: DEFAULT_FILTERS}] instead of [DEFAULT_FILTERS]
type Filters = {
  [key: string]: any
}

type SearchState = {
  filters: Filters
  renderFromNewFilter: boolean
  wins: number
  losses: number
  ties: number
  history: {
    items: Filters[]
    currentIndex: number
    stopBackIndex: number | null
  }
}

const FilterContext = React.createContext({})
FilterContext.displayName = 'FilterContext'

// Return true if there is at least one filter not in the skip list
function hasRenderableFilter(filters: Filters) {
  return Object.keys(filters).some(
    (filter: string) => !skipFilters.includes(filter)
  )
}

function useFilters() {
  const context = React.useContext(FilterContext)
  if (!context) {
    throw new Error(`useFilters must be used within a FilterProvider`)
  }
  return context
}

function getLastHistory(state: SearchState): [any, any, Filters, boolean] {
  const stopBackIndex =
    state.history.stopBackIndex ??
    (state.history.currentIndex + HISTORY_SIZE + 1) % HISTORY_SIZE
  const lastIndex =
    (HISTORY_SIZE + (state.history.currentIndex - 1)) % HISTORY_SIZE
  const lastFilters = state.history.items[lastIndex]
  const canGoBack =
    state.history.currentIndex !== state.history.stopBackIndex &&
    Boolean(lastFilters)

  return [stopBackIndex, lastIndex, lastFilters, canGoBack]
}

function setFilterKey(state: any, key: any, value: any) {
  let currentFilters = Object.assign({}, state.filters)

  const exclusiveFilters: string[] = mutuallyExclusiveFilters
    .filter((filters) => filters.includes(key))
    .flat()

  // If the filter is unset, it should be deleted from state.
  // Otherwise, we need to check if is part of a mutually exclusive group (like a set of check boxes)
  // If it's just a regular field, without dependencies, set the value
  if (value === '') {
    delete currentFilters[key]
  } else if (exclusiveFilters.length > 0) {
    const filtersToTurnOff: string[] = exclusiveFilters.filter(
      (args) => !args.includes(key)
    )
    currentFilters[key] = value
    filtersToTurnOff.forEach((filter) => delete currentFilters[filter])
  } else {
    currentFilters[key] = value
  }
  return currentFilters
}

function filterReducer(state: any, action: any) {
  let currentFilters: any
  let key: string, value: string
  let histories, nextIndex

  switch (action.type) {
    case 'GO_BACK':
      // const currentIndex = history.currentIndex
      const [stopBackIndex, lastIndex, lastFilters, canGoBack] =
        getLastHistory(state)

      if (canGoBack) {
        return {
          ...state,
          filters: lastFilters,
          renderFromNewFilter: true,
          history: {
            ...state.history,
            currentIndex: lastIndex,
            stopBackIndex: stopBackIndex,
          },
        }
      }
      return state // no changes if we can't go back
    case 'CHANGE_FILTER':
      ;({key, value} = action.filter)

      const updatedFilters = setFilterKey(state, key, value)

      histories = state.history.items.slice(0)
      nextIndex = (state.history.currentIndex + 1) % HISTORY_SIZE
      histories[nextIndex] = updatedFilters

      return {
        ...state,
        filters: updatedFilters,
        renderFromNewFilter: !skipFilters.includes(key),
        history: {
          items: histories,
          currentIndex: nextIndex,
          stopBackIndex: null,
        },
      }
    case 'CHANGE_AGG':
      // const id = e.target.id.split('agg-link-')[1]
      ;({key, value} = action.filter)
      // setState({filters: {[key]: value}})
      histories = state.history.items.slice(0)
      nextIndex = (state.history.currentIndex + 1) % HISTORY_SIZE
      histories[nextIndex] = {filters: {[key]: value}}
      return {
        ...state,
        filters: {[key]: value},
        history: {
          items: histories,
          currentIndex: nextIndex,
          stopBackIndex: null,
        },
      }
    case 'RESET':
      currentFilters = Object.assign({}, state.filters)

      Object.keys(currentFilters).forEach((key) => {
        delete currentFilters[key]
      })
      currentFilters = Object.assign({}, DEFAULT_FILTERS)
      return {
        ...state,
        filters: currentFilters,
        renderFromNewFilter: true,
        history: DEFAULT_HISTORY,
      }
    default:
      throw new Error(
        `Undefined action "${action.type}" passed to filterReducer`
      )
  }
}

function FilterProvider(props: any) {
  const [state, dispatch] = React.useReducer(filterReducer, {
    filters: DEFAULT_FILTERS,
    renderFromNewFilter: false,
    wins: 0,
    losses: 0,
    ties: 0,
    history: DEFAULT_HISTORY,
    // // Not used yet.  Sort state is being stored with SortContext.
    // sort: {
    //   // order: col.split('table-').slice(1).join('-'),
    //   order: 'date',
    //   direction: sortDirections.desc,
    // },
  })
  return <FilterContext.Provider value={[state, dispatch]} {...props} />
}

export {
  useFilters,
  FilterProvider,
  getLastHistory,
  Filters,
  hasRenderableFilter,
}
