"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Input = exports.FormGroup = exports.Dialog = exports.CircleButton = exports.Button = void 0;
var _react = require("@emotion/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var colors = _interopRequireWildcard(require("../styles/colors"));
var _dialog = require("@reach/dialog");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/** @jsx jsx */

// This stopped working when I updated to @emotion/react from @emotion/core
// import styled from '@emotion/styled/marco'

const buttonVariants = {
  primary: {
    backgroundColor: colors.indigo,
    color: colors.base
  },
  secondary: {
    backgroundColor: colors.gray,
    color: colors.indigoDarken10
  }
};
const Button = exports.Button = _styled.default.button({
  padding: '1rem 1.5rem',
  border: '0',
  lineHeight: '1',
  borderRadius: '3px',
  cursor: 'pointer'
}, ({
  variant = 'primary'
}) => buttonVariants[variant]);
const Input = exports.Input = _styled.default.input({
  borderRadius: '3px',
  border: `1px solid ${colors.gray10}`,
  background: colors.gray,
  padding: '8px 12px'
});
const FormGroup = exports.FormGroup = _styled.default.div({
  display: 'flex',
  flexDirection: 'column'
});
const Dialog = exports.Dialog = (0, _styled.default)(_dialog.Dialog)({
  maxWidth: '450px',
  borderRadius: '3px',
  paddingBottom: '3.5em',
  boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.2)',
  margin: '20vh auto',
  '@media (max-width: 991px)': {
    width: '100%',
    margin: '10vh auto'
  }
});
const CircleButton = exports.CircleButton = _styled.default.button({
  borderRadius: '30px',
  padding: '0',
  width: '40px',
  height: '40px',
  lineHeight: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'white',
  color: colors.text,
  border: `1px solid ${colors.gray10}`,
  cursor: 'pointer'
});