import React from 'react'
import {Controller} from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

export default function TextInput({control, errors, field, label}) {
  const hasError = Boolean(errors) && Boolean(errors[field])

  return (
    <>
      <Controller
        name={field}
        control={control}
        defaultValue=""
        render={({field: fields}) => {
          return (
            <TextField
              {...(hasError ? {error: true} : {})}
              {...fields}
              id={field}
              label={label}
              variant="outlined"
              helperText={hasError ? errors[field].message : ''}
            />
          )
        }}
      />
    </>
  )
}
