import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {InputLabel} from '@mui/material'
import {Controller} from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

const rankings = Array.from({length: 25}, (_, i) => i + 1).concat('UNRANKED')

export function RankInput({control, field, label, errors, sx = {width: 132}}) {
  const cleanField = field.replaceAll('_', '-')
  const hasError = Boolean(errors) && Boolean(errors[field])

  return (
    <Controller
      name={field}
      control={control}
      render={({field: {onChange, value = '', ...rest}}) => {
        return (
          <FormControl size="small" {...(hasError ? {error: true} : {})}>
            <InputLabel id={cleanField}>{label}</InputLabel>
            <Select
              labelId={cleanField}
              id={cleanField + '-select'}
              value={value}
              label={label}
              onChange={onChange}
              sx={sx}
            >
              {rankings.map((rank) => (
                <MenuItem key={rank} value={rank}>
                  {rank}
                </MenuItem>
              ))}
            </Select>
            {hasError ? (
              <FormHelperText>{errors[field].message}</FormHelperText>
            ) : null}
          </FormControl>
        )
      }}
    />
  )
}
