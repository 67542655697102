"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Admin2 = Admin2;
var _react = require("@emotion/react");
var _react2 = _interopRequireDefault(require("react"));
var _modal = require("../components/modal");
require("@reach/dialog/styles.css");
var _auth0React = require("@auth0/auth0-react");
var _LoginButton = require("../components/LoginButton");
var _LogoutButton = require("../components/LogoutButton");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/** @jsx jsx */

// import {Button} from '../components/lib'
// import 'bootstrap/dist/css/bootstrap-reboot.css'

// function LoginButton() {
//   const {loginWithRedirect, ...rest} = useAuth0()
//   console.log(rest)
//   return (
//     <Button
//       onClick={() => loginWithRedirect({appState: {returnTo: '/profile'}})}
//     >
//       Login
//     </Button>
//   )
// }

// function LogoutButton() {
//   const {logout} = useAuth0()
//   return (
//     <Button
//       onClick={() =>
//         logout({
//           logoutParams: {
//             returnTo: `${window.location.origin}/admin2`,
//           },
//         })
//       }
//     >
//       Logout
//     </Button>
//   )
// }

function Profile() {
  const {
    user,
    isAuthenticated,
    isLoading
  } = (0, _auth0React.useAuth0)();
  if (isLoading) {
    return (0, _react.jsx)("div", null, "Loading ...");
  }
  if (isAuthenticated) {
    console.log(user);
  }
  return isAuthenticated && (0, _react.jsx)("div", null, (0, _react.jsx)("img", {
    src: user.picture,
    alt: user.name
  }), (0, _react.jsx)("h2", null, user.name), (0, _react.jsx)("p", null, user.email));
}
function Admin2() {
  return (0, _react.jsx)("div", {
    css: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '50vh'
    }
  }, (0, _react.jsx)("h1", null, "Admin"), (0, _react.jsx)("div", {
    css: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
      gridGap: '0.75rem'
    }
  }, (0, _react.jsx)(Profile, null), (0, _react.jsx)(_modal.Modal, null, (0, _react.jsx)(_modal.ModalOpenButton, null, (0, _react.jsx)(_LoginButton.LoginButton, null))), (0, _react.jsx)(_modal.Modal, null, (0, _react.jsx)(_modal.ModalOpenButton, null, (0, _react.jsx)(_LogoutButton.LogoutButton, null)))));
}