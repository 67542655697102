import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {FormAddCoach} from './FormAddCoach'
import {useFormSubmitter} from '../../hooks/useFormSubmitter'
import SnackBar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import {confirmSubmissionAndResetForm} from '../../lib/confirm_submissions_and_reset_form'
import {SnackBarInputType} from './Form.types'
import {M_CREATE_COACH} from './mutations/CreateCoach'
import {
  CoachSchema,
  defaultValues,
  inputTransformer,
} from './schemas/CoachSchema'

export function ModalAddCoach({
  state,
  handler,
}: {
  state: boolean
  handler: any
}) {
  const {
    control,
    handleSubmit,
    formErrors,
    getValues,
    onSubmit,
    apolloError,
    reset,
    setValue,
  } = useFormSubmitter(
    M_CREATE_COACH,
    CoachSchema,
    defaultValues,
    inputTransformer
  )
  const [snackBarState, setSnackBarState] = React.useState<SnackBarInputType>({
    open: false,
    message: '',
    severity: undefined,
  })
  const onSubmitWrapper = confirmSubmissionAndResetForm<SnackBarInputType>(
    onSubmit,
    setSnackBarState,
    'coach',
    reset
  )

  const onClose = () => {
    setSnackBarState({...snackBarState, open: false})
  }

  return (
    <Dialog open={state} onClose={() => handler('coach')}>
      <SnackBar
        open={snackBarState.open}
        onClose={onClose}
        autoHideDuration={5000}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert severity={snackBarState.severity} sx={{width: '100%'}}>
          <Box sx={{pt: 0.25, fontSize: '1rem'}}>{snackBarState.message}</Box>
        </Alert>
      </SnackBar>
      <DialogTitle>Add Coach</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{pb: 1}}>
          To add a new coach, please fill out the form below.
        </DialogContentText>
        <FormAddCoach
          handleSubmit={handleSubmit(onSubmitWrapper)}
          control={control}
          errors={formErrors}
          apolloError={apolloError}
          getValues={getValues}
          setValue={setValue}
        />
      </DialogContent>
    </Dialog>
  )
}
