import React from 'react'
import {ProviderProps} from '../lib/types'

const TeamContext = React.createContext({})
TeamContext.displayName = 'TeamContext'
const useTeamContext = () => React.useContext(TeamContext)

type TeamsCollectionReduceType = {
  [key: string]: {
    [key: string]: string
  }
}

type TeamReduceType = {
  [key: string]: string
}

function TeamProvider({children, data}: ProviderProps) {
  const teams =
    data?.teams?.reduce(
      (acc: TeamsCollectionReduceType, curr: TeamReduceType) => {
        return {
          ...acc,
          [curr.name]: {
            ...curr,
          },
        }
      },
      {}
    ) || {}
  return <TeamContext.Provider value={teams} children={children} />
}

export {TeamContext, useTeamContext, TeamProvider}
