import React from 'react'
import {withAuthenticationRequired} from '@auth0/auth0-react'
// import {PageLoader} from './page-loader'
import {useAuth0} from '@auth0/auth0-react'
import {Navigate} from 'react-router-dom'

const allowedRoles = ['User', 'Game Admin']

const WithClaimCheck = (Component, claimCheckFunction, returnTo) => {
  const {user, isLoading} = useAuth0()

  if (isLoading) {
    return () => <div>loading...</div>
  } else {
    if (claimCheckFunction(user)) {
      return Component
    } else {
      return () => <Navigate to={returnTo} />
    }
  }
}

const checkClaims = (user) => {
  return user?.['https://games.uhnd.com/jwt/claims/role']?.some((role) =>
    allowedRoles.includes(role)
  )
}

export const AuthenticationGuard = ({component}) => {
  const {user, getIdTokenClaims} = useAuth0()

  React.useEffect(() => {
    async function getRoles() {
      await getIdTokenClaims()
    }
    getRoles()
  }, [getIdTokenClaims])

  const Component = withAuthenticationRequired(
    WithClaimCheck(component, checkClaims, '/404'),
    {
      onRedirecting: () => <div className="page-layout">authenticating...</div>,
    }
  )

  return <Component />
}
