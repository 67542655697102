import React from 'react'
import {compareGames} from '../lib/game_helpers'
import Icon from '../svg/icons.svg'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {
  useSort,
  defaultDirections,
  sortDirections,
  handleSort,
} from './SortContext'
import {styled} from '@mui/system'
import {TableTooltip} from './TableTooltip'
import {Filters} from './FilterContext'

type ColHeaders = {
  [key: string]: string
}

interface GameResultsTableResultsProps {
  colHeaders: ColHeaders
  matchingGames: Array<any>
  teams: {[key: string]: any}
  filters: Filters
}

function getRankingToDisplay(filters: Filters, game: any, usOrThem: string) {
  const whichRanking = filters?.rankingAtMatch
    ? `${usOrThem}_rank`
    : filters?.rankingAtEndOfSeason
    ? `${usOrThem}_final_rank`
    : false
  const ranking = whichRanking ? game[whichRanking] : ''
  return parseInt(ranking) ? ranking : '' // Lots of non-numerical ranks are stored that we aren't interested in (unranked, tbd, no poll, etc)
}

export default function GameResultsTableResults({
  colHeaders,
  matchingGames,
  teams,
  filters,
}: GameResultsTableResultsProps) {
  const [sortState, sortDispatch]: any = useSort()
  const hasResults = matchingGames.length !== 0
  matchingGames.sort(compareGames(sortState.order, sortState.direction))

  const resultRows: any[] = []

  matchingGames.forEach((game) => {
    const team = teams[game.opponent.name]

    const ndRank = getRankingToDisplay(filters, game, 'nd')
    const oppRank = getRankingToDisplay(filters, game, 'opp')
    resultRows.push(
      <tr key={game.id}>
        <td className="results-table__date">{game.date}</td>
        <td className="results-table__result">
          {game.result}
          {ndRank ? ` (#${ndRank})` : ''}
        </td>
        <td className="results-table__site">{game.site}</td>
        <td className="results-table__nd-coach__full_name">
          <TableTooltip
            href={game.ndCoach.wiki_url}
            className="coach__full_name--tooltip"
          >
            {game.ndCoach.full_name}
          </TableTooltip>
        </td>
        <td className="results-table__opp-coach__full_name">
          <TableTooltip
            href={game.oppCoach.wiki_url}
            className="coach__full_name--tooltip"
          >
            {game.oppCoach.full_name}
          </TableTooltip>
        </td>
        <td className="results-table__nd-score">{game.nd_score}</td>
        <td className="results-table__opp-score">{game.opp_score}</td>
        <td className="results-table__opponent__name">
          <TableTooltip
            // we don't want to store the same url on every game
            // href={game.opponent.wiki_team_url.url}
            href={team.wiki_team_url?.url}
            className="results-table__opponent__name--tooltip"
            team={team}
            season={game.season}
          >
            {game.opponent.name}
            {oppRank ? ` (#${oppRank})` : ''}
          </TableTooltip>
        </td>
      </tr>
    )
  })

  // Use alphabetical ordering to "toggle" current sort direction
  const sortDirection =
    sortState.direction > sortDirections.asc
      ? sortDirections.asc
      : sortDirections.desc

  return (
    <>
      <thead>
        <tr>
          {Object.entries(colHeaders).map(([k, v]) => {
            const active = sortState.order === k
            // NOTE: id's are used for sorting
            return (
              <th
                id={`table-${k}`}
                onClick={(e) => {
                  handleSort(e, sortState, sortDispatch, defaultDirections)
                }}
                className={`results-table__header results-table__${k}`}
                key={k}
              >
                <svg
                  className={`results-table__header-icon ${
                    active && hasResults
                      ? `results-table__header-icon--active results-table__header-icon--${sortDirection}`
                      : null
                  }`}
                  fill="black"
                  width="14px"
                  height="14px"
                >
                  <use xlinkHref={`${Icon}#icon-sort-${sortDirection}`} />
                </svg>
                <span className={`results-table__header-span`}>{v}</span>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>{resultRows}</tbody>
    </>
  )
}
