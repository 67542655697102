/** @jsx jsx */
import {jsx} from '@emotion/react'
import styled from '@emotion/styled'
// This stopped working when I updated to @emotion/react from @emotion/core
// import styled from '@emotion/styled/marco'
import * as colors from '../styles/colors'
import {Dialog as ReachDialog} from '@reach/dialog'

const buttonVariants = {
  primary: {
    backgroundColor: colors.indigo,
    color: colors.base,
  },
  secondary: {
    backgroundColor: colors.gray,
    color: colors.indigoDarken10,
  },
}

export const Button = styled.button(
  {
    padding: '1rem 1.5rem',
    border: '0',
    lineHeight: '1',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  ({variant = 'primary'}) => buttonVariants[variant]
)

export const Input = styled.input({
  borderRadius: '3px',
  border: `1px solid ${colors.gray10}`,
  background: colors.gray,
  padding: '8px 12px',
})

export const FormGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const Dialog = styled(ReachDialog)({
  maxWidth: '450px',
  borderRadius: '3px',
  paddingBottom: '3.5em',
  boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.2)',
  margin: '20vh auto',
  '@media (max-width: 991px)': {
    width: '100%',
    margin: '10vh auto',
  },
})

export const CircleButton = styled.button({
  borderRadius: '30px',
  padding: '0',
  width: '40px',
  height: '40px',
  lineHeight: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'white',
  color: colors.text,
  border: `1px solid ${colors.gray10}`,
  cursor: 'pointer',
})
