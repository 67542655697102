import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import {gql, useLazyQuery} from '@apollo/client'

const Q_WIKI_SEASON_URL = gql`
  query WikiSeasonUrls($team_id: Int!, $season: Int!) {
    external_urls(
      limit: 1
      where: {team_id: {_eq: $team_id}, _and: {season: {_eq: $season}}}
    ) {
      id
      url
      team_id
    }
  }
`
type TeamType = {
  id: number
  name: string
  nickname: string
  wiki_team_url: string
}

type TableTooltipProps = {
  className: string
  href: string
  team?: TeamType
  season?: number
  children?: React.ReactNode
}

export function TableTooltip({
  className,
  href,
  team,
  season,
  children,
}: TableTooltipProps) {
  const [getUrls, {data}] = useLazyQuery(Q_WIKI_SEASON_URL)

  const team_wiki_url = data?.external_urls[0]?.url

  const handleGetUrl =
    team && season
      ? () => getUrls({variables: {team_id: team.id, season: season}})
      : undefined

  return href ? (
    <Tooltip
      title={
        <React.Fragment>
          <Typography>
            {team && season ? (
              <span>
                <Link
                  href={team_wiki_url}
                  target="_blank"
                  underline={team_wiki_url ? 'hover' : 'none'}
                  rel="noreferrer"
                  sx={{
                    color: 'white',
                    ...(team_wiki_url ? {} : {fontStyle: 'italic'}),
                  }}
                >
                  {season} Team Page
                </Link>
                <span> | </span>
              </span>
            ) : undefined}
            <Link
              href={href}
              target="_blank"
              underline="hover"
              rel="noreferrer"
              sx={{color: 'white'}}
            >
              Wiki Page
            </Link>
          </Typography>
        </React.Fragment>
      }
      placement="left"
      enterTouchDelay={200}
      // leaveDelay={700}
      onMouseEnter={handleGetUrl}
      onTouchStart={handleGetUrl}
      arrow
    >
      <p className={className}>{children}</p>
    </Tooltip>
  ) : (
    <p className={className}>{children}</p>
  )
}
