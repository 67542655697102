import React from 'react'
import {DocumentNode, useMutation} from '@apollo/client'
import * as Apollo from '@apollo/client'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import {
  OpponentSchema,
  OpponentInputType,
} from '../components/forms/schemas/OpponentSchema'
import {graphql} from 'graphql'

const useFormSubmitter = <T,>(
  MUTATION: Apollo.DocumentNode,
  schema: any,
  defaultValues: any,
  inputTransformer: (data: any) => any = (data) => {
    return {
      object: {...data},
    }
  }
) => {
  const {
    control,
    formState: {errors: formErrors, isValid},
    getValues,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  // Empty lists aren't considered "nulls".  Using an invalid field here
  // to force an error if no valid input is submitted.
  const [graphqlData, setGraphqlData] = React.useState<{objects: any[]}>({
    objects: [{invalid_field: null}],
  })
  const [createObject, {data, loading, error: apolloError}] =
    Apollo.useMutation(MUTATION)

  const onSubmit = async (data: T) => {
    const graphqlData = inputTransformer(data)
    setGraphqlData(graphqlData)

    let co: object = {}
    try {
      co = await createObject({
        variables: graphqlData,
      })
    } catch (error) {
      co = {errors: ['Could not create coach']}
    }

    return co
  }

  return {
    apolloError,
    control,
    formErrors,
    getValues,
    handleSubmit,
    isValid,
    onSubmit,
    reset,
    setValue,
  }
}

export {useFormSubmitter}
