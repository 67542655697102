import * as React from 'react'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {Controller} from 'react-hook-form'
import TextField from '@mui/material/TextField'

export function DateInput({control, field, errors}) {
  const hasError = Boolean(errors) && Boolean(errors[field])

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={field}
          control={control}
          render={({
            field: {onChange: handleChange, value = null, ...rest},
            fieldState: {error, invalid},
          }) => {
            return (
              <DatePicker
                value={value}
                onChange={handleChange}
                slotProps={{
                  textField: {
                    helperText: hasError ? errors.date?.message : null,
                    error: invalid,
                  },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    </div>
  )
}
