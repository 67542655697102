import React, {RefObject} from 'react'
import {PageLayout} from '../components/PageLayout'
import FormAddGame from '../components/forms/FormAddGame'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import AddCircle from '@mui/icons-material/AddCircle'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {visuallyHidden} from '@mui/utils'
import SnackBar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

let count = 0

export function AdminAddGame({handleSubmitFromProps = null}) {
  // const formRef = React.useRef(null)
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    message: '',
    severity: undefined,
  })
  const [games, setGames] = React.useState([
    {
      id: count,
      component: (<FormAddGame />) as React.JSX.Element,
      ref: React.createRef<HTMLInputElement | null>(),
    },
  ])

  const handleSubmit =
    handleSubmitFromProps ??
    ((e: any) => {
      games.forEach((game) => {
        if (game.ref.current) {
          game.ref.current?.dispatchEvent(
            new Event('submit', {cancelable: true, bubbles: true})
          )
        }
      })
    })

  const handleAddFormRow = (e: any) => {
    const ref = React.createRef<HTMLInputElement | null>()
    setGames([...games, {id: ++count, component: <FormAddGame />, ref: ref}])
  }

  const handleRemoveFormRow = (id: any) => {
    const newGames = games.filter((game) => game.id !== id)
    // setGames([...games.slice(0, -1)])
    setGames(newGames)
  }

  const onCloseSnackBar = () => {
    setSnackBarState({...snackBarState, open: false})
  }

  return (
    <PageLayout>
      <SnackBar
        open={snackBarState.open}
        onClose={onCloseSnackBar}
        autoHideDuration={5000}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert severity={snackBarState.severity} sx={{width: '100%'}}>
          <Box sx={{pt: 0.25, fontSize: '1rem'}}>{snackBarState.message}</Box>
        </Alert>
      </SnackBar>

      <Container maxWidth={false}>
        <div>
          <h1>Add Games</h1>
        </div>
        <Tooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Add Another Game</Typography>
            </React.Fragment>
          }
          placement="right"
          arrow
        >
          <IconButton
            size="large"
            aria-label="Add form row"
            aria-controls="game-form"
            aria-haspopup="false"
            onClick={handleAddFormRow}
            color="primary"
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
        <Box id="game-form">
          {games.map((game) => {
            return (
              <Stack
                key={game.id}
                direction="row"
                spacing={2}
                sx={{pb: 3}}
                alignItems="center"
              >
                {React.cloneElement(game.component, {ref: game.ref})}
                <IconButton
                  size="large"
                  aria-label="Remove form row"
                  aria-controls="game-form"
                  aria-haspopup="false"
                  onClick={() => handleRemoveFormRow(game.id)}
                  sx={{color: 'red'}}
                >
                  <CloseIcon
                    fontSize="large"
                    sx={game.id === 0 ? visuallyHidden : null}
                  />
                </IconButton>
              </Stack>
            )
          })}
          {/* <FormAddGame id={0} ref={formRef} /> */}
        </Box>
        <Button onClick={handleSubmit} variant="outlined" size="large">
          Submit
        </Button>
      </Container>
    </PageLayout>
  )
}
