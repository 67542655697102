import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {InputLabel} from '@mui/material'
import {Controller, useWatch} from 'react-hook-form'
import FormControl from '@mui/material/FormControl'

export function ResultInput({control, errors, setValue}) {
  const nd_score = Number(useWatch({control, name: 'nd_score'}))
  const opp_score = Number(useWatch({control, name: 'opp_score'}))

  // Set the result based on the score
  React.useEffect(() => {
    if (nd_score && opp_score) {
      if (nd_score > opp_score) {
        setValue('result', 'W')
      } else if (nd_score < opp_score) {
        setValue('result', 'L')
      } else {
        setValue('result', 'T')
      }
    }
  })

  return (
    <Controller
      name="result"
      control={control}
      render={({field: {onChange, value = '', ...rest}}) => {
        return (
          <FormControl size="small" disabled>
            <InputLabel id="result">Result</InputLabel>
            <Select
              labelId="result"
              id="result-select"
              value={value}
              label="Result"
              sx={{width: 100}}
              // onChange={onChange}
              inputProps={{readOnly: true}}
            >
              <MenuItem value="W">Win</MenuItem>
              <MenuItem value="L">Loss</MenuItem>
              <MenuItem value="T">Tie</MenuItem>
            </Select>
          </FormControl>
        )
      }}
    />
  )
}
