import React from 'react'
import {Controller} from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export function CheckBoxInput({control, field, label}) {
  return (
    <Controller
      name={field}
      control={control}
      render={({field: {onChange, value = false, ...rest}}) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                size="medium"
                value={value}
                onChange={onChange}
                inputProps={{'aria-label': label}}
              />
            }
            label={label}
          />
        )
      }}
    />
  )
}
