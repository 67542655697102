import React from 'react'
import {ProviderProps} from '../lib/types'

const GameContext = React.createContext({})
GameContext.displayName = 'GameContext'
// const useGameContext = () => React.useContext(GameContext)

function useGameContext() {
  const context = React.useContext(GameContext)
  if (context === undefined) {
    throw new Error(`useGameContext must be used within a GameProvider`)
  }
  return context
}

function GameProvider({children, data}: ProviderProps) {
  return <GameContext.Provider value={data} children={children} />
}

export {GameContext, useGameContext, GameProvider}
