import React, {ReactNode} from 'react'
import {SplashScreen} from '@capacitor/splash-screen'
import './sass/main.scss'
import {GameProvider} from './components/GameContext'
import {SortProvider} from './components/SortContext'
import {FilterProvider} from './components/FilterContext'
import SearchBar from './components/SearchBar'
import GameResultsTable from './components/GameResultsTable'
import {ALL_DATA} from './gql/all_data'
import {useQuery} from '@apollo/client'
import {TeamProvider} from './components/TeamContext'

export default function App(): JSX.Element {
  const {loading, error, data} = useQuery(ALL_DATA)
  const hideSplash = async () => {
    await SplashScreen.hide()
  }
  ;(window as any).data = data
  if (loading) {
    return (
      <div
        style={{padding: 10 + 'px'}}
        dangerouslySetInnerHTML={{__html: 'Loading...'}}
      />
    )
  } else {
    hideSplash()
  }

  return (
    <div className="filterable-game-table">
      <GameProvider data={data}>
        <TeamProvider data={data}>
          <SortProvider>
            <FilterProvider>
              <SearchBar />
              <GameResultsTable />
            </FilterProvider>
          </SortProvider>
        </TeamProvider>
      </GameProvider>
    </div>
  )
}
