import React from 'react'
import {gql} from '@apollo/client'
import {AutoCompleteInput} from './AutoCompleteInput'
import {useWatch} from 'react-hook-form'

const Q_SEASONS = gql`
  query seasons {
    games(distinct_on: season, order_by: {season: desc_nulls_last}) {
      season
    }
  }
`

export function SeasonInput(props) {
  const date = useWatch({control: props.control, name: 'date'})

  React.useEffect(() => {
    if (date) {
      // January is a bowl game and is in the previous season
      if (date.$M === 0) {
        props.setValue('season', date.$y - 1)
      } else {
        props.setValue('season', date.$y)
      }
    }
  }, [date, props])

  return (
    <AutoCompleteInput
      query={Q_SEASONS}
      field="season"
      {...props}
      sx={{width: 100}}
    />
  )
}
