import React from 'react'
import {useGameContext} from './GameContext'
import {useTeamContext} from './TeamContext'
import {useFilters, hasRenderableFilter} from './FilterContext'
import {
  includeGame,
  calculateRecordFor,
  calculatePointsFor,
  getAggCols,
} from '../lib/game_helpers'
import GameResultsTableResults from './GameResultsTableResults'
import GameResultsTableResultsAgg from './GameResultsTableResultsAgg'

export default function GameResultsTable(props: any) {
  const gameContext: {} = useGameContext()
  const teams: {} = useTeamContext()
  const [{filters, renderFromNewFilter}]: any = useFilters()
  const {games}: any = gameContext

  const [matchingGames, setMatchingGames] = React.useState([])

  React.useEffect(() => {
    function getMatchingGames(games: any) {
      const matchingGames: any = []
      // Check if we want to render from a new filter, and if the list of filters
      // includes some that require a render
      if (hasRenderableFilter(filters)) {
        games.forEach((game: any) => {
          let filterCount = Object.keys(filters).length
          if (filterCount > 0 && includeGame(filters, game)) {
            matchingGames.push(game)
          }
        })
      }
      return matchingGames
    }

    if (renderFromNewFilter) {
      setMatchingGames(getMatchingGames(games))
    }
  }, [renderFromNewFilter, games, filters]) // include filters because we want to re-render when filters change

  // console.log(`Sort by ${sortState.order}, dir=${sortState.direction}`)
  // window.g = matchingGames.slice().sort(compare(this.sortState.order))

  const record = calculateRecordFor(matchingGames)
  const points = calculatePointsFor(matchingGames)

  let winningPercentage =
    record.wins / (record.wins + record.losses + record.ties)
  winningPercentage = winningPercentage ? winningPercentage : 0.0

  return (
    <div className="results">
      <div className="fieldset">
        <fieldset>
          <legend>
            Results (
            {Number.parseFloat(winningPercentage as unknown as string).toFixed(
              3
            )}
            )
          </legend>
          <div>
            <span role="cell">Wins: {record.wins} </span>
            <span role="cell">Losses: {record.losses} </span>
            <span role="cell">Ties: {record.ties} </span>
            <span role="cell">Total: {record.total} </span>
          </div>
          <div>
            <span role="cell">Points Scored: {points.scored}</span>
            <span role="cell">Points Allowed: {points.allowed}</span>
          </div>
        </fieldset>
      </div>
      <div className="results-table">
        <div className="results-table__game-list">
          <table>
            {filters.groupings ? (
              <GameResultsTableResultsAgg
                colHeaders={getAggCols(filters.groupings)}
                matchingGames={matchingGames}
                {...props}
              />
            ) : (
              <GameResultsTableResults
                colHeaders={{
                  date: 'Date',
                  result: 'Result',
                  site: 'Site',
                  'nd-coach__full_name': 'ND Coach',
                  'opp-coach__full_name': 'Opponent Coach',
                  nd_score: 'ND Score',
                  opp_score: 'Opponent Score',
                  opponent__name: 'Opponent',
                }}
                matchingGames={matchingGames}
                teams={teams}
                filters={filters}
              />
            )}
          </table>
        </div>
      </div>
    </div>
  )
}
