import React from 'react'
import {gql, useSubscription} from '@apollo/client'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {InputLabel} from '@mui/material'
import {Controller} from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

const Q_TEAMS = gql`
  subscription opponents {
    teams(where: {active: {_eq: true}}, order_by: {name: asc_nulls_last}) {
      id
      name
    }
  }
`

export function OpponentInput({control, field, errors}) {
  const {data} = useSubscription(Q_TEAMS)
  const hasError = Boolean(errors) && Boolean(errors[field])

  // const opponents = [{label: 'Team 1', id: 1}]

  const opponents =
    data?.teams.map((team) => ({
      label: team.name,
      id: team.id,
    })) || []

  // const handleFocus = (e) => {
  //   getOpponents()
  // }

  return (
    <Controller
      name={field}
      control={control}
      render={({field: {onChange: handleChange, value = '', ...rest}}) => {
        return (
          <FormControl size="small" {...(hasError ? {error: true} : {})}>
            <InputLabel id="opponent">Opponent</InputLabel>
            <Select
              labelId="opponent"
              id="opponent-select"
              value={value}
              label="Opponent"
              // onFocus={handleFocus}
              onChange={handleChange}
              sx={{width: 142}}
            >
              {opponents.map((opponent) => (
                <MenuItem key={opponent.id} value={opponent.id}>
                  {opponent.label}
                </MenuItem>
              ))}
            </Select>
            {hasError ? (
              <FormHelperText>{errors[field].message}</FormHelperText>
            ) : null}
          </FormControl>
        )
      }}
    />
  )
}
