/** @jsx jsx */
import {jsx} from '@emotion/react'
import {CircleButton, Dialog} from './lib'
// import {Dialog} from '@reach/dialog'
import {VisuallyHidden} from '@reach/visually-hidden'

import * as React from 'react'

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(args))

const ModalContext = React.createContext()

function Modal(props) {
  const [isOpen, setIsOpen] = React.useState(false)
  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

function ModalOpenButton({children}) {
  const [, setIsOpen] = React.useContext(ModalContext)
  return React.cloneElement(children, {
    onClick: callAll(() => setIsOpen(true), children.props.onClick),
  })
}

function ModalContentsBase(props) {
  const [isOpen, setIsOpen] = React.useContext(ModalContext)
  return (
    <Dialog isOpen={isOpen} onDismiss={() => setIsOpen(false)} {...props} />
  )
}

function ModalDismissButton({children: child, ...props}) {
  const [, setIsOpen] = React.useContext(ModalContext)
  return React.cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  })
}

function ModalContents(props) {
  return (
    <ModalContentsBase>
      <div css={{display: 'flex', justifyContent: 'flex-end'}}>
        <ModalDismissButton>
          <CircleButton>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>X</span>
          </CircleButton>
        </ModalDismissButton>
      </div>
      <h3 css={{textAlign: 'center', fontSize: '2em'}}>{props.title}</h3>
      {props.children}
    </ModalContentsBase>
  )
}

export {Modal, ModalOpenButton, ModalContents, ModalDismissButton}
